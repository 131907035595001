import React, { useContext, useEffect, useState, createRef } from 'react'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import DownloadIcon from '@mui/icons-material/Download';
import {
  Stack,
} from '@mui/material';
import Typography from '../../components/Typography';
import { SmallCard } from '../Sections/small-card';
import { Graph } from '../Sections/graph';
import { Box } from '@mui/material';
import BoxPlot from '../Sections/BoxPlot';
import BarGraph from '../Sections/BarGraph';
// import { data_bar } from "../data/mockdatabar"
// import { data_bar_ethnicity } from "../data/mockdatabar_ethnicity";
// import { data_bar_race } from "../data/mockdatabar_race";
// import { data_bar_veteran } from "../data/mockdatabar_veteran";
// import { data_bar_disability } from "../data/mockdatabar_disability";
import Chip from '@mui/material/Chip';
import CssBaseline from '@mui/material/CssBaseline';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import DashboardContext from '../../context';
import { useScreenshot, createFileName } from 'use-react-screenshot'
import Button from '@mui/material/Button';
import { Flex } from '@aws-amplify/ui-react';
import { useTheme } from '@mui/material/styles';

import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import PersonIcon from '@mui/icons-material/Person';
import Person4Icon from '@mui/icons-material/Person4';
import Paper from '@mui/material/Paper';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';

const styles = {
  bold: {
    fontWeight: 'bold',
  },
  blueText: {
    color: 'blue',
  },
  greenText: {
    color: '#66FF00',
  },
  redText: {
    color: '#ff009e',
  },
};
export default function Dashboard() {
  const ctx = useContext(DashboardContext);
  console.log(ctx.statsData);
  const theme = useTheme();
  const primaryColor = theme.palette.primary;
  const secondaryColor = theme.palette.secondary;
  const pGenValue = ctx.statsData?.data?.p_gen;
  const pEthValue = ctx.statsData?.data?.p_eth;
  const pRacValue = ctx.statsData?.data?.p_rac;
  const pVetValue = ctx.statsData?.data?.p_vet;
  const pDisValue = ctx.statsData?.data?.p_dis;
  const [genderChart, setGenderChart] = useState([]);
  const [ethnicityChart, setEthnicityChart] = useState([]);
  const [raceChart, setRaceChart] = useState([]);
  const [veteranChart, setVeteranChart] = useState([]);
  const [disabilityChart, setDisabilityChart] = useState([]);
  const [chartType, setChartType] = React.useState('Gender');
  const ref = createRef(null);

  const [navValue, setNavalue] = React.useState(0);
  const [image, takeScreenShot] = useScreenshot({
    type: "image/jpeg",
    quality: 1.0
  });

  // function refreshMessages() {
  //   const getRandomInt = (max) => Math.floor(Math.random() * Math.floor(max));

  //   return Array.from(new Array(50)).map(
  //     () => messageExamples[getRandomInt(messageExamples.length)],
  //   );
  // }

  const download = (image, { name = "img", extension = "jpg" } = {}) => {
    const a = document.createElement("a");
    a.href = image;
    a.download = createFileName(extension, name);
    a.click();
  };

  const downloadScreenshot = () => takeScreenShot(ref.current).then(download);

  const handleChange = (event) => {
    setChartType(event.target.value);
  };
  const axisLeft = {
    tickSize: 0,
    tickPadding: 5,
    tickRotation: 0,
    legend: 'Gender Percentage',
    legendPosition: 'middle',
    legendOffset: -40
  };
  const axisBottom = {
    tickSize: 0,
    tickPadding: 5,
    tickRotation: 0,
    legend: 'Gender Category',
    legendPosition: 'middle',
    legendOffset: 32
  }
  const axisLeft_ethnicity = {
    tickSize: 0,
    tickPadding: 5,
    tickRotation: 0,
    legend: 'Ethnicity Percentage',
    legendPosition: 'middle',
    legendOffset: -40
  };
  const axisBottom_ethnicity = {
    tickSize: 0,
    tickPadding: 5,
    tickRotation: 0,
    legend: 'Ethnicity Category',
    legendPosition: 'middle',
    legendOffset: 32
  }
  const axisLeft_race = {
    tickSize: 0,
    tickPadding: 5,
    tickRotation: 0,
    legend: 'Race Percentage',
    legendPosition: 'middle',
    legendOffset: -40
  };
  const axisBottom_race = {
    tickSize: 0,
    tickPadding: 5,
    tickRotation: -10,
    legend: 'Race Category',
    legendPosition: 'middle',
    legendOffset: 40
  }
  const axisLeft_veteran = {
    tickSize: 0,
    tickPadding: 5,
    tickRotation: 0,
    legend: 'Veteran Percentage',
    legendPosition: 'middle',
    legendOffset: -40
  };
  const axisBottom_veteran = {
    tickSize: 0,
    tickPadding: 5,
    tickRotation: 0,
    legend: 'Veteran Category',
    legendPosition: 'middle',
    legendOffset: 32
  }
  const axisLeft_disability = {
    tickSize: 0,
    tickPadding: 5,
    tickRotation: 0,
    legend: 'Veteran Percentage',
    legendPosition: 'middle',
    legendOffset: -40
  };
  const axisBottom_disability = {
    tickSize: 0,
    tickPadding: 5,
    tickRotation: 0,
    legend: 'Veteran Category',
    legendPosition: 'middle',
    legendOffset: 32
  }

  const makeDataForChart = (topic, data) => {
    let dataArray = [];
    switch (topic) {
      case "gender":
        console.log(">>>>>>>>>>>>>>>>>>>>>>>", data)
        dataArray.push(
          {
            "gender": "Male",
            "Company": data.filter((item) => (item.gender === 'male'))[0]?.percent,
            "Students": data.filter((item) => (item.gender === 'male'))[0]?.percent,
            "Faculty": data.filter((item) => (item.gender === 'male'))[0]?.percent,
            "Staff": data.filter((item) => (item.gender === 'male'))[0]?.percent,
            "companyColor": "hsl(30, 70%, 50%)",
            "County": ctx.censusData.filter((item) => (item.geo_id === ctx.companyData.geo_id))[0]?.sex_male_total,
            "countyColor": "hsl(66, 70%, 50%)",
          })

        dataArray.push(
          {
            "gender": "Female",
            "Company": data.filter((item) => (item.gender === 'female'))[0]?.percent,
            "Students": data.filter((item) => (item.gender === 'female'))[0]?.percent,
            "Faculty": data.filter((item) => (item.gender === 'female'))[0]?.percent,
            "Staff": data.filter((item) => (item.gender === 'female'))[0]?.percent,

            "companyColor": "hsl(321, 70%, 50%)",
            "County": ctx.censusData.filter((item) => (item.geo_id === ctx.companyData.geo_id))[0]?.sex_female_total,
            "countyColor": "hsl(203, 70%, 50%)",
          })
        setGenderChart(dataArray)
        break;

      case "ethnicity":
        dataArray.push({
          "ethnicity": "Hispanic or Latino",
          "Company": data.filter((item) => (item.ethnicity === "hispanic"))[0]?.percent,
          "Students": data.filter((item) => (item.ethnicity === "hispanic"))[0]?.percent,
          "Faculty": data.filter((item) => (item.ethnicity === "hispanic"))[0]?.percent,
          "Staff": data.filter((item) => (item.ethnicity === "hispanic"))[0]?.percent,

          "companyColor": "hsl(321, 70%, 50%)",
          "County": ctx.censusData.filter((item) => (item.geo_id == ctx.companyData.geo_id))[0]?.eth_hisp,
          "countyColor": "hsl(203, 70%, 50%)",
        })
        dataArray.push({
          "ethnicity": "Non Hispanic or Latino",
          "Company": data.filter((item) => (item.ethnicity === "non hispanic"))[0]?.percent,
          "Students": data.filter((item) => (item.ethnicity === "non hispanic"))[0]?.percent,
          "Faculty": data.filter((item) => (item.ethnicity === "non hispanic"))[0]?.percent,
          "Staff": data.filter((item) => (item.ethnicity === "non hispanic"))[0]?.percent,
          "companyColor": "hsl(30, 70%, 50%)",
          "County": ctx.censusData.filter((item) => (item.geo_id == ctx.companyData.geo_id))[0]?.eth_not_hisp,
          "countyColor": "hsl(66, 70%, 50%)",
        });
        setEthnicityChart(dataArray);
        break;

      case "race":
        dataArray.push({
          "race": "Native American",
          "Company": data.filter((item) => (item.race === "black"))[0]?.percent,
          "Students": data.filter((item) => (item.race === "black"))[0]?.percent,
          "Faculty": data.filter((item) => (item.race === "black"))[0]?.percent,
          "Staff": data.filter((item) => (item.race === "black"))[0]?.percent,
          "companyColor": "hsl(321, 70%, 50%)",
          "County": ctx.censusData.filter((item) => (item.geo_id == ctx.companyData.geo_id))[0]?.race_american_indian,
          "countyColor": "hsl(203, 70%, 50%)",
        })
        dataArray.push({
          "race": "White",
          "Company": data.filter((item) => (item.race === "white"))[0]?.percent,
          "Students": data.filter((item) => (item.race === "white"))[0]?.percent,
          "Faculty": data.filter((item) => (item.race === "white"))[0]?.percent,
          "Staff": data.filter((item) => (item.race === "white"))[0]?.percent,
          "companyColor": "hsl(30, 70%, 50%)",
          "County": ctx.censusData.filter((item) => (item.geo_id == ctx.companyData.geo_id))[0]?.race_white,
          "countyColor": "hsl(66, 70%, 50%)",
        })
        dataArray.push({
          "race": "Asian",
          "Company": data.filter((item) => (item.race === "asian"))[0]?.percent,
          "Students": data.filter((item) => (item.race === "asian"))[0]?.percent,
          "Faculty": data.filter((item) => (item.race === "asian"))[0]?.percent,
          "Staff": data.filter((item) => (item.race === "asian"))[0]?.percent,
          "companyColor": "hsl(30, 70%, 50%)",
          "County": ctx.censusData.filter((item) => (item.geo_id == ctx.companyData.geo_id))[0]?.race_asian,
          "countyColor": "hsl(66, 70%, 50%)",
        })
        dataArray.push({
          "race": "Black",
          "Company": data.filter((item) => (item.race === "black"))[0]?.percent,
          "Students": data.filter((item) => (item.race === "black"))[0]?.percent,
          "Faculty": data.filter((item) => (item.race === "black"))[0]?.percent,
          "Staff": data.filter((item) => (item.race === "black"))[0]?.percent,
          "companyColor": "hsl(30, 70%, 50%)",
          "County": ctx.censusData.filter((item) => (item.geo_id == ctx.companyData.geo_id))[0]?.race_black,
          "countyColor": "hsl(66, 70%, 50%)",
        })
        dataArray.push({
          "race": "Pacific Islander",
          "Company": data.filter((item) => (item.race === "pacific islander"))[0]?.percent,
          "Students": data.filter((item) => (item.race === "pacific islander"))[0]?.percent,
          "Faculty": data.filter((item) => (item.race === "pacific islander"))[0]?.percent,
          "Staff": data.filter((item) => (item.race === "pacific islander"))[0]?.percent,
          "companyColor": "hsl(30, 70%, 50%)",
          "County": ctx.censusData.filter((item) => (item.geo_id == ctx.companyData.geo_id))[0]?.race_haw_pac,
          "countyColor": "hsl(66, 70%, 50%)",
        })
        dataArray.push({
          "race": "Not disclosed",
          "Company": data.filter((item) => (item.race === "not disclosed"))[0]?.percent,
          "Students": data.filter((item) => (item.race === "not disclosed"))[0]?.percent,
          "Faculty": data.filter((item) => (item.race === "not disclosed"))[0]?.percent,
          "Staff": data.filter((item) => (item.race === "not disclosed"))[0]?.percent,
          "companyColor": "hsl(30, 70%, 50%)",
          "County": ctx.censusData.filter((item) => (item.geo_id == ctx.companyData.geo_id))[0]?.race_others,
          "countyColor": "hsl(66, 70%, 50%)",
        })
        setRaceChart(dataArray);
        break;

      case "veteran":
        dataArray.push({
          "status": "Veterans",
          "Company": data.filter((item) => (item.is_veteran === "1"))[0]?.percent,
          "Students": data.filter((item) => (item.is_veteran === "1"))[0]?.percent,
          "Faculty": data.filter((item) => (item.is_veteran === "1"))[0]?.percent,
          "Staff": data.filter((item) => (item.is_veteran === "1"))[0]?.percent,
          "companyColor": "hsl(321, 70%, 50%)",
          "County": ctx.censusData.filter((item) => (item.geo_id == ctx.companyData.geo_id))[0]?.veteran,
          "countyColor": "hsl(203, 70%, 50%)",
        });
        setVeteranChart(dataArray);
        break;

      case "disability":
        dataArray.push({
          "status": "Disability",
          "Company": data.filter((item) => (item.is_disable === "1"))[0]?.percent,
          "Students": data.filter((item) => (item.is_disable === "1"))[0]?.percent,
          "Faculty": data.filter((item) => (item.is_disable === "1"))[0]?.percent,
          "Staff": data.filter((item) => (item.is_disable === "1"))[0]?.percent,
          "companyColor": "hsl(321, 70%, 50%)",
          "County": ctx.censusData.filter((item) => (item.geo_id == ctx.companyData.geo_id))[0]?.disability,
          "countyColor": "hsl(203, 70%, 50%)",
        });
        setDisabilityChart(dataArray);
        break;

      default:
        console.log("category not defined");
    }
  }

  const CustomizedBarGraph = () => {
    let legendKeys = [];
    if (ctx.companyData.company_code === "BS01") {
      legendKeys = navValue === 0 ? ["Students", "County"] : navValue === 1 ? ["Faculty", "County"] : ["Students", "Faculty"];
    } else {
      legendKeys = ["Company", "County"]
    }
    return legendKeys;
    // return (
    //   <BarGraph
    //     legendKeys={legendKeys}
    //     indexBy={field}
    //     data={data}
    //     axisLeft={axisLeft}
    //     axisBottom={axisBottom}
    //   />
    // );
  }
  useEffect(() => {
    makeDataForChart("gender", ctx.genderData);
    makeDataForChart("ethnicity", ctx.ethnicityData);
    makeDataForChart("race", ctx.raceData);
    makeDataForChart("veteran", ctx.veteranData);
    makeDataForChart("disability", ctx.disableData);
    return () => {
    };
  }, [ctx]);

  // useEffect(() => {
  //   ref.current.ownerDocument.body.scrollTop = 0;
  //   setMessages(refreshMessages());
  // }, [navValue, setMessages]);
  return (
    <div style={{ width: '90%', height: "100%" }}>
      <Box sx={{ display: 'flex', justifyContent: 'center', marginLeft: '30%', flexDirection: 'column', mt: "1em", height: "100%" }}>
        <Box sx={{
          width: '100%',
          height: '1em',
          padding: '1em',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          mt: '2em'
        }}>
          <Typography variant="h4" marked="center" component="h2" sx={{ mb: 4 }}>
            Welcome to your dashboard
          </Typography>
        </Box>
        <Box sx={{ minWidth: 120 }}>
          <FormControl  >
            <InputLabel id="demo-simple-select-label">Select</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={chartType}
              label="Age"
              onChange={handleChange}
              sx={{ backgroundColor: secondaryColor.light }}
            >
              <MenuItem value={"Gender"}>Gender</MenuItem>
              <MenuItem value={"Ethnicity"}>Ethnicity </MenuItem>
              <MenuItem value={"Race"}>Race </MenuItem>
              <MenuItem value={"Veteran"}>Veteran  </MenuItem>
              <MenuItem value={"Disability"}>Disability  </MenuItem>
              <MenuItem value={"Urm"}>URM  </MenuItem>
              <MenuItem value={"Pell"}>Pell  </MenuItem>
              <MenuItem value={"FirstGeneration"}>First-Generation  </MenuItem>
            </Select>
          </FormControl>
        </Box>
        {chartType === "Gender" && <Box sx={{ display: 'flex', flexDirection: 'column' }}><div ref={ref}><Box sx={{
          width: '100%',
          height: '60vh',
          boxShadow: 'rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px',
          borderRadius: '5px',
          padding: '1em',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          margin: '1em 0em 1em 0em',
        }}>
          <Typography variant="body1" component="h2">Gender Distribution</Typography>
          <BarGraph
            legendKeys={ctx.companyData.company_code === "EI01"
              ? navValue === 0 ? ["Students", "County"] : (navValue === 1 ? ["Faculty", "County"] : (navValue === 2 ? ["Students", "Faculty"] : ["Staff", "County"]))
              : ["Company", "County"]}
            indexBy={"gender"}
            data={genderChart}
            axisLeft={axisLeft}
            axisBottom={axisBottom} />
        </Box></div>
          <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
          }}>
            <Box >
              <Chip label={pGenValue < 0.05 ? "Distribution does not Reflects the Community" : "Distribution Reflects the Community"} variant="outlined" sx={{ color: pGenValue < 0.05 ? 'red' : 'green', background: secondaryColor.light }} />
              <Chip label={pGenValue < 0.05 ? "Action Needed" : "No Action Needed "} variant="outlined" sx={{ color: pGenValue < 0.05 ? 'red' : 'green', background: secondaryColor.light }} />
            </Box>
            <Box>
              <Button style={{
                marginBottom: '10px', fontSize: 12,
                color: 'white',
                borderRadius: '1em',
                backgroundColor: '#71502f',
              }} onClick={downloadScreenshot}><DownloadIcon />
                Download
              </Button>
            </Box>
          </div>
        </Box>}
        {chartType === "Ethnicity" && <><div ref={ref}><Box sx={{
          width: '100%',
          height: '60vh',
          boxShadow: 'rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px',
          borderRadius: '5px',
          padding: '1em',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          margin: '1em 0em 1em 0em'
        }}>
          <Typography variant="body1">Ethnicity Distribution</Typography>
          <BarGraph
            legendKeys={ctx.companyData.company_code === "EI01"
              ? navValue === 0 ? ["Students", "County"] : navValue === 1 ? ["Faculty", "County"] : navValue === 2 ? ["Students", "Faculty"] : ["Staff", "County"]
              : ["Company", "County"]}
            indexBy="ethnicity"
            data={ethnicityChart}
            axisLeft={axisLeft_ethnicity}
            axisBottom={axisBottom_ethnicity} />
          {/* {<CustomizedBarGraph field="Ethnicity" ethnicityChart axisLeft_ethnicity axisBottom_ethnicity />} */}

        </Box></div>
          <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
          }}>
            <Box >
              <Chip label={pEthValue < 0.05 ? "Distribution does not Reflects the Community" : "Distribution Reflects the Community"} variant="outlined" sx={{ color: pEthValue < 0.05 ? 'red' : 'green', background: secondaryColor.light }} />
              <Chip label={pEthValue < 0.05 ? "Action Needed" : "No Action Needed "} variant="outlined" sx={{ color: pEthValue < 0.05 ? 'red' : 'green', background: secondaryColor.light }} />
            </Box>
            <Box>
              <Button style={{
                marginBottom: '10px',
                fontSize: 12,
                color: 'white',
                borderRadius: '1em',
                backgroundColor: '#71502f',
              }} onClick={downloadScreenshot}><DownloadIcon />
                Download
              </Button>
            </Box>
          </div></>}
        {chartType === "Race" && <><div ref={ref}><Box sx={{
          width: '100%',
          height: '60vh',
          boxShadow: 'rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px',
          borderRadius: '5px',
          padding: '1em',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          margin: '1em 0em 1em 0em'
        }}>

          <Typography variant="body1">Race Distribution</Typography>
          <BarGraph
            legendKeys={ctx.companyData.company_code === "EI01"
              ? navValue === 0 ? ["Students", "County"] : navValue === 1 ? ["Faculty", "County"] : navValue === 2 ? ["Students", "Faculty"] : ["Staff", "County"]
              : ["Company", "County"]}
            indexBy={"race"}
            data={raceChart}
            axisLeft={axisLeft_race}
            axisBottom={axisBottom_race} />

        </Box>
        </div>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', }}>
            <Box >
              <Chip label={pRacValue < 0.05 ? "Distribution does not Reflects the Community" : "Distribution Reflects the Community"} variant="outlined" sx={{ color: pRacValue < 0.05 ? 'red' : 'green', background: secondaryColor.light }} />
              <Chip label={pRacValue < 0.05 ? "Action Needed" : "No Action Needed "} variant="outlined" sx={{ color: pRacValue < 0.05 ? 'red' : 'green', background: secondaryColor.light }} />
            </Box>
            <Box>
              <Button style={{
                marginBottom: '10px', fontSize: 12,
                color: 'white',
                borderRadius: '1em',
                backgroundColor: '#71502f',
              }} onClick={downloadScreenshot}><DownloadIcon />
                Download
              </Button>
            </Box>
          </div>
        </>}
        {chartType === "Veteran" && <><div ref={ref}><Box sx={{
          width: '100%',
          height: '60vh',
          boxShadow: 'rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px',
          borderRadius: '5px',
          padding: '1em',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          margin: '1em 0em 1em 0em'
        }}>

          <Typography variant="body1">Veteran Distribution</Typography>
          <BarGraph
            legendKeys={ctx.companyData.company_code === "EI01"
              ? navValue === 0 ? ["Students", "County"] : navValue === 1 ? ["Faculty", "County"] : navValue === 2 ? ["Students", "Faculty"] : ["Staff", "County"]
              : ["Company", "County"]}
            indexBy={"status"}
            data={veteranChart}
            axisLeft={axisLeft_veteran}
            axisBottom={axisBottom_veteran} />

        </Box>
        </div>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', }}>
            <Box >
              <Chip label={pVetValue < 0.05 ? "Distribution does not Reflects the Community" : "Distribution Reflects the Community"} variant="outlined" sx={{ color: pVetValue < 0.05 ? 'red' : 'green', background: secondaryColor.light }} />
              <Chip label={pVetValue < 0.05 ? "Action Needed" : "No Action Needed "} variant="outlined" sx={{ color: pVetValue < 0.05 ? 'red' : 'green', background: secondaryColor.light }} />
            </Box>
            <Box>
              <Button style={{
                marginBottom: '10px',
                fontSize: 12,
                color: 'white',
                borderRadius: '1em',
                backgroundColor: '#71502f',
              }} onClick={downloadScreenshot}><DownloadIcon />
                Download
              </Button>
            </Box>
          </div>
        </>}
        {chartType === "Disability" && <><div ref={ref}><Box sx={{
          width: '100%',
          height: '60vh',
          boxShadow: 'rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px',
          borderRadius: '5px',
          padding: '1em',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          margin: '1em 0em 1em 0em'
        }}>

          <Typography variant="body1">Disability Distribution</Typography>
          <BarGraph
            legendKeys={ctx.companyData.company_code === "EI01"
              ? navValue === 0 ? ["Students", "County"] : navValue === 1 ? ["Faculty", "County"] : navValue === 2 ? ["Students", "Faculty"] : ["Staff", "County"]
              : ["Company", "County"]}
            indexBy={"status"}
            data={disabilityChart}
            axisLeft={axisLeft_disability}
            axisBottom={axisBottom_disability} />


        </Box></div>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', }}>
            <Box >
              <Chip label={pDisValue < 0.05 ? "Distribution does not Reflects the Community" : "Distribution Reflects the Community"} variant="outlined" sx={{ color: pDisValue < 0.05 ? 'red' : 'green', background: secondaryColor.light }} />
              <Chip label={pDisValue < 0.05 ? "Action Needed" : "No Action Needed "} variant="outlined" sx={{ color: pDisValue < 0.05 ? 'red' : 'green', background: secondaryColor.light }} />
            </Box>
            <Box>
              <Button style={{
                marginBottom: '10px',
                fontSize: 12,
                color: 'white',
                borderRadius: '1em',
                backgroundColor: '#71502f',
              }} onClick={downloadScreenshot}><DownloadIcon />
                Download
              </Button>
            </Box>
          </div>
        </>}
        {chartType === "Urm" && <Box sx={{ display: 'flex', flexDirection: 'column' }}><div ref={ref}><Box sx={{
          width: '100%',
          height: '60vh',
          boxShadow: 'rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px',
          borderRadius: '5px',
          padding: '1em',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          margin: '1em 0em 1em 0em',
        }}>
          <Typography variant="body1" component="h2">URM : African American, Latinx, or American Indian</Typography>
          <BarGraph
            legendKeys={ctx.companyData.company_code === "EI01"
              ? navValue === 0 ? ["Students", "County"] : navValue === 1 ? ["Faculty", "County"] : navValue === 2 ? ["Students", "Faculty"] : ["Staff", "County"]
              : ["Company", "County"]}
            indexBy={"gender"}
            data={genderChart}
            axisLeft={axisLeft}
            axisBottom={axisBottom} />
        </Box></div>
          <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
          }}>
            <Box >
              <Chip label={pGenValue < 0.05 ? "Distribution does not Reflects the Community" : "Distribution Reflects the Community"} variant="outlined" sx={{ color: pGenValue < 0.05 ? 'red' : 'green', background: secondaryColor.light }} />
              <Chip label={pGenValue < 0.05 ? "Action Needed" : "No Action Needed "} variant="outlined" sx={{ color: pGenValue < 0.05 ? 'red' : 'green', background: secondaryColor.light }} />
            </Box>
            <Box>
              <Button style={{
                marginBottom: '10px', fontSize: 12,
                color: 'white',
                borderRadius: '1em',
                backgroundColor: '#71502f',
              }} onClick={downloadScreenshot}><DownloadIcon />
                Download
              </Button>
            </Box>
          </div>
        </Box>}
        {chartType === "Pell" && <Box sx={{ display: 'flex', flexDirection: 'column' }}><div ref={ref}><Box sx={{
          width: '100%',
          height: '60vh',
          boxShadow: 'rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px',
          borderRadius: '5px',
          padding: '1em',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          margin: '1em 0em 1em 0em',
        }}>
          <Typography variant="body1" component="h2">Pell : Receive Pell grant in first term</Typography>
          <BarGraph
            legendKeys={ctx.companyData.company_code === "EI01"
              ? navValue === 0 ? ["Students", "County"] : navValue === 1 ? ["Faculty", "County"] : navValue === 2 ? ["Students", "Faculty"] : ["Staff", "County"]
              : ["Company", "County"]}
            indexBy={"gender"}
            data={genderChart}
            axisLeft={axisLeft}
            axisBottom={axisBottom} />
        </Box></div>
          <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
          }}>
            <Box >
              <Chip label={pGenValue < 0.05 ? "Distribution does not Reflects the Community" : "Distribution Reflects the Community"} variant="outlined" sx={{ color: pGenValue < 0.05 ? 'red' : 'green', background: secondaryColor.light }} />
              <Chip label={pGenValue < 0.05 ? "Action Needed" : "No Action Needed "} variant="outlined" sx={{ color: pGenValue < 0.05 ? 'red' : 'green', background: secondaryColor.light }} />
            </Box>
            <Box>
              <Button style={{
                marginBottom: '10px', fontSize: 12,
                color: 'white',
                borderRadius: '1em',
                backgroundColor: '#71502f',
              }} onClick={downloadScreenshot}><DownloadIcon />
                Download
              </Button>
            </Box>
          </div>
        </Box>}
        {chartType === "FirstGeneration" && <Box sx={{ display: 'flex', flexDirection: 'column' }}><div ref={ref}><Box sx={{
          width: '100%',
          height: '60vh',
          boxShadow: 'rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px',
          borderRadius: '5px',
          padding: '1em',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          margin: '1em 0em 1em 0em',
        }}>
          <Typography variant="body1" component="h2">First Generation : self-identify as a first-generation college student (first in the family to go to college)
          </Typography>
          <BarGraph
            legendKeys={ctx.companyData.company_code === "EI01"
              ? navValue === 0 ? ["Students", "County"] : navValue === 1 ? ["Faculty", "County"] : navValue === 2 ? ["Students", "Faculty"] : ["Staff", "County"]
              : ["Company", "County"]}
            indexBy={"gender"}
            data={genderChart}
            axisLeft={axisLeft}
            axisBottom={axisBottom} />
        </Box></div>
          <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
          }}>
            <Box >
              <Chip label={pGenValue < 0.05 ? "Distribution does not Reflects the Community" : "Distribution Reflects the Community"} variant="outlined" sx={{ color: pGenValue < 0.05 ? 'red' : 'green', background: secondaryColor.light }} />
              <Chip label={pGenValue < 0.05 ? "Action Needed" : "No Action Needed "} variant="outlined" sx={{ color: pGenValue < 0.05 ? 'red' : 'green', background: secondaryColor.light }} />
            </Box>
            <Box>
              <Button style={{
                marginBottom: '10px', fontSize: 12,
                color: 'white',
                borderRadius: '1em',
                backgroundColor: '#71502f',
              }} onClick={downloadScreenshot}><DownloadIcon />
                Download
              </Button>
            </Box>
          </div>
        </Box>}
        {ctx.companyData.company_code === "EI01" && <Box sx={{ pb: 7, display: 'flex', justifyContent: 'center' }}>
          <Paper sx={{ position: 'fixed', bottom: 0, width: '40%', bgcolor: 'black' }} elevation={24} square={false}>
            <BottomNavigation
              sx={{ backgroundColor: '#FFF7ED' }}
              showLabels
              value={navValue}
              onChange={(event, newValue) => {
                setNavalue(newValue);
              }}
            >
              <BottomNavigationAction label="Student vs County" icon={<PersonIcon />} />
              <BottomNavigationAction label="Faculty vs County" icon={<Person4Icon />} />
              <BottomNavigationAction label="Student vs Faculty" icon={<SupervisorAccountIcon />} />
              <BottomNavigationAction label="Staff vs County" icon={<SupervisorAccountIcon />} />
            </BottomNavigation>
          </Paper>
        </Box>}

        {/* <Typography variant="body1" component="h2">First-time 1-year retention rate: 10%</Typography>
        <Typography variant="body1" component="h2">Transfer 1-year retention rate: 10%</Typography>
        <Typography variant="body1" component="h2">Graduate 1-year retention rate: 10%</Typography>

        <Typography variant="body1" component="h2">First-time 4-year graduation rate: 10%</Typography>
        <Typography variant="body1" component="h2">First-time 6-year graduation rate: 10%</Typography>
        <Typography variant="body1" component="h2">Transfer 2-year graduation rate: 10%</Typography>
        <Typography variant="body1" component="h2">Transfer 4-year graduation rate: 10%</Typography>
        <Typography variant="body1" component="h2">Graduate 1-year graduation rate: 10%</Typography>
        <Typography variant="body1" component="h2">Graduate 2-year graduation rate: 10%</Typography>
        <Typography variant="body1" component="h2">Graduate 3-year graduation rate: 10%</Typography>

        <Typography variant="body1" component="h2">URM Equity Gap: 10%</Typography>
        <Typography variant="body1" component="h2">Pell Equity Gap: 10%</Typography>
        <Typography variant="body1" component="h2">First-Generation Equity Gap: 10%</Typography> */}
      </Box>

    </div>)
}
