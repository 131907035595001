import React, { useContext, useEffect, useState, createRef } from 'react'

import Typography from "../../components/Typography";
import { Box } from "@mui/material";
import Button from "@mui/material/Button";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import DashboardContext from '../../context';
import { useTheme } from '@mui/material/styles';

// import { DataGrid } from "@mui/x-data-grid";
import {
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridActionsCellItem,
  GridRowEditStopReasons,
} from "@mui/x-data-grid";
import axios from "axios";

export default function UploadData() {
  const [genderRowData, setGenderRowData] = useState([]);
  const [ethnicityRowData, setEthnicityRowData] = useState([]);
  const [raceRowData, setRaceRowData] = useState([]);
  const [veteranRowData, setVeteranRowData] = useState([]);
  const [disabilityRowData, setDisabilityRowData] = useState([]);
  // const [rowModesModel, setRowModesModel] = React.useState({});
  const [dataType, setDataType] = React.useState('Gender');
  const theme = useTheme();
  const primaryColor = theme.palette.primary;
  const secondaryColor = theme.palette.secondary;
  const ctx = useContext(DashboardContext);

  const gender_url =
    `https://ej0t9qbga6.execute-api.us-west-1.amazonaws.com/prod/upload/gender?company_code=${ctx.companyData.company_code}`;
  const ethnicity_url =
    `https://ej0t9qbga6.execute-api.us-west-1.amazonaws.com/prod/upload/ethnicity?company_code=${ctx.companyData.company_code}`;
  const race_url =
    `https://ej0t9qbga6.execute-api.us-west-1.amazonaws.com/prod/upload/race?company_code=${ctx.companyData.company_code}`;
  const veteran_url =
    `https://ej0t9qbga6.execute-api.us-west-1.amazonaws.com/prod/upload/veteran?company_code=${ctx.companyData.company_code}`;
  const disability_url =
    `https://ej0t9qbga6.execute-api.us-west-1.amazonaws.com/prod/upload/disability?company_code=${ctx.companyData.company_code}`;

  const gender_columns = [
    // { field: "row_id", headerName: "Row ID", flex: 1, editable: true },
    // { field: "company_id", headerName: "Company ID", flex: 1, editable: true },
    // {
    //   field: "company_name",
    //   headerName: "Company Name",
    //   flex: 1,
    //   editable: true,
    // },
    // {
    //   field: "company_or_county",
    //   headerName: "Company/County",
    //   flex: 1,
    //   editable: true,
    // },
    {
      field: "gender",
      headerName: "Gender",
      flex: 1,
      editable: false, headerClassName: 'custom-header-row'
    },
    {
      field: "count",
      headerName: "Count",
      flex: 1,
      editable: true, headerClassName: 'custom-header-row'
    },
    // {
    //   field: "county_name",
    //   headerName: "County Name",
    //   flex: 1,
    //   editable: true,
    // },
    // {
    //   field: "county_id",
    //   headerName: "County ID",
    //   flex: 1,
    //   editable: true,
    // },

    {
      field: "percent",
      headerName: "Percent",
      flex: 1,
      editable: true, headerClassName: 'custom-header-row'
    },
  ];
  const ethnicity_columns = [
    // { field: "row_id", headerName: "Row ID", flex: 1, editable: true },
    // { field: "company_id", headerName: "Company ID", flex: 1, editable: true },
    // {
    //   field: "company_name",
    //   headerName: "Company Name",
    //   flex: 1,
    //   editable: true,
    // },
    // {
    //   field: "company_or_county",
    //   headerName: "Company/County",
    //   flex: 1,
    //   editable: true,
    // },
    {
      field: "ethnicity",
      headerName: "Ethnicity",
      flex: 1,
      editable: false, headerClassName: 'custom-header-row'
    },
    {
      field: "count",
      headerName: "Count",
      flex: 1,
      editable: true, headerClassName: 'custom-header-row'
    },
    // {
    //   field: "county_name",
    //   headerName: "County Name",
    //   flex: 1,
    //   editable: true,
    // },
    // {
    //   field: "county_id",
    //   headerName: "County ID",
    //   flex: 1,
    //   editable: true,
    // },

    {
      field: "percent",
      headerName: "Percent",
      flex: 1,
      editable: true, headerClassName: 'custom-header-row'
    },
  ];
  const race_columns = [
    // { field: "row_id", headerName: "Row ID", flex: 1, editable: true },
    // { field: "company_id", headerName: "Company ID", flex: 1, editable: true },
    // {
    //   field: "company_name",
    //   headerName: "Company Name",
    //   flex: 1,
    //   editable: true,
    // },
    {
      field: "race",
      headerName: "Race",
      flex: 1,
      editable: false, headerClassName: 'custom-header-row'
    },
    // {
    //   field: "company_or_county",
    //   headerName: "Company/County",
    //   flex: 1,
    //   editable: true,
    // },
    {
      field: "count",
      headerName: "Count",
      flex: 1,
      editable: true, headerClassName: 'custom-header-row'
    },
    // {
    //   field: "county_name",
    //   headerName: "County Name",
    //   flex: 1,
    //   editable: true,
    // },
    // {
    //   field: "county_id",
    //   headerName: "County ID",
    //   flex: 1,
    //   editable: true,
    // },

    {
      field: "percent",
      headerName: "Percent",
      flex: 1,
      editable: true, headerClassName: 'custom-header-row'
    },
  ];

  const veteran_columns = [
    // { field: "row_id", headerName: "Row ID", flex: 1, editable: true },
    // { field: "company_id", headerName: "Company ID", flex: 1, editable: true },
    // {
    //   field: "company_name",
    //   headerName: "Company Name",
    //   flex: 1,
    //   editable: true,
    // },
    // {
    //   field: "company_or_county",
    //   headerName: "Company/County",
    //   flex: 1,
    //   editable: true,
    // },
    {
      field: "is_veteran",
      headerName: "Veteran",
      flex: 1,
      editable: false, headerClassName: 'custom-header-row'
    },
    {
      field: "count",
      headerName: "Count",
      flex: 1,
      editable: true, headerClassName: 'custom-header-row'
    },
    // {
    //   field: "county_name",
    //   headerName: "County Name",
    //   flex: 1,
    //   editable: true,
    // },
    // {
    //   field: "county_id",
    //   headerName: "County ID",
    //   flex: 1,
    //   editable: true,
    // },
    {
      field: "percent",
      headerName: "Percent",
      flex: 1,
      editable: true, headerClassName: 'custom-header-row'
    },

  ];

  const disability_columns = [
    {
      field: "is_disable",
      headerName: "Disable",
      flex: 1,
      editable: false, headerClassName: 'custom-header-row'
    },
    {
      field: "count",
      headerName: "Count",
      flex: 1,
      editable: true, headerClassName: 'custom-header-row'
    },
    {
      field: "percent",
      headerName: "Percent",
      flex: 1,
      editable: true, headerClassName: 'custom-header-row'
    },

  ];

  React.useEffect(() => {
    fetchGenderData();
    fetchEthnicityData();
    fetchRaceData();
    fetchVeteranData();
    fetchDisabilityData();
  }, []);

  // const fetchGenderData = () => {
  //   return fetch(gender_url, {
  //     // headers: {
  //     //   "x-api-key": "NUQg1g8vg07dnS30ujuHi2TCGjloWdai7X9tPCy0",
  //     // },
  //   })
  //     .then((res) => res.json())
  //     .then((data) => {
  //       const items = data.Items || []; // Check if "Items" array exists
  //       let genderData = [];
  //       items.map((item) => {
  //         genderData.push({
  //           id: item.row_id.N,
  //           row_id: item.row_id.N,
  //           company_id: item.company_id.N,
  //           company_or_county: item.company_or_county.S,
  //           company_name: item.company_name.S,
  //           count: item.count.N,
  //           county_id: item.county_id.N,
  //           county_name: item.county_name.S,
  //           gender: item.gender.S,
  //           percent: item.percent.S,
  //         });
  //       });
  //       setGenderRowData(genderData);
  //     });
  // };
  // const fetchEthnicityData = () => {
  //   return fetch(ethnicity_url, {
  //     // headers: {
  //     //   "x-api-key": "NUQg1g8vg07dnS30ujuHi2TCGjloWdai7X9tPCy0",
  //     // },
  //   })
  //     .then((res) => res.json())
  //     .then((data) => {
  //       const items = data.Items || []; // Check if "Items" array exists
  //       let ethnicityData = [];
  //       items.map((item) => {
  //         ethnicityData.push({
  //           id: item.row_id.N,
  //           row_id: item.row_id.N,
  //           company_id: item.company_id.N,
  //           company_or_county: item.company_or_county.S,
  //           company_name: item.company_name.S,
  //           count: item.count.N,
  //           county_id: item.county_id.N,
  //           county_name: item.county_name.S,
  //           ethnicity: item.ethnicity.S,
  //           percent: item.percent.S,
  //         });
  //       });
  //       setEthnicityRowData(ethnicityData);
  //     });
  // };
  // const fetchRaceData = () => {
  //   return fetch(race_url, {
  //     // headers: {
  //     //   "x-api-key": "NUQg1g8vg07dnS30ujuHi2TCGjloWdai7X9tPCy0",
  //     // },
  //   })
  //     .then((res) => res.json())
  //     .then((data) => {
  //       const items = data.Items || []; // Check if "Items" array exists
  //       let raceData = [];
  //       items.map((item) => {
  //         raceData.push({
  //           id: item.row_id.N,
  //           row_id: item.row_id.N,
  //           company_id: item.company_id.N,
  //           company_or_county: item.company_or_county.S,
  //           company_name: item.company_name.S,
  //           count: item.count.N,
  //           county_id: item.county_id.N,
  //           county_name: item.county_name.S,
  //           race: item.race.S,
  //           percent: item.percent.S,
  //         });
  //       });
  //       setRaceRowData(raceData);
  //     });
  // };

  // const fetchVeteranData = () => {
  //   return fetch(veteran_url, {
  //     // headers: {
  //     //   "x-api-key": "NUQg1g8vg07dnS30ujuHi2TCGjloWdai7X9tPCy0",
  //     // },
  //   })
  //     .then((res) => res.json())
  //     .then((data) => {
  //       const items = data.Items || []; // Check if "Items" array exists
  //       let raceData = [];
  //       items.map((item) => {
  //         raceData.push({
  //           id: item.row_id.N,
  //           row_id: item.row_id.N,
  //           company_id: item.company_id.N,
  //           company_or_county: item.company_or_county.S,
  //           company_name: item.company_name.S,
  //           count: item.count.N,
  //           county_id: item.county_id.N,
  //           county_name: item.county_name.S,
  //           percent: item.percent.S,
  //         });
  //       });
  //       setVeteranRowData(raceData);
  //     });
  // };

  // const fetchDisabilityData = () => {
  //   return fetch(disability_url, {
  //     // headers: {
  //     //   "x-api-key": "NUQg1g8vg07dnS30ujuHi2TCGjloWdai7X9tPCy0",
  //     // },
  //   })
  //     .then((res) => res.json())
  //     .then((data) => {
  //       const items = data.Items || []; // Check if "Items" array exists
  //       let raceData = [];
  //       items.map((item) => {
  //         raceData.push({
  //           id: item.row_id.N,
  //           row_id: item.row_id.N,
  //           company_id: item.company_id.N,
  //           company_or_county: item.company_or_county.S,
  //           company_name: item.company_name.S,
  //           count: item.count.N,
  //           county_id: item.county_id.N,
  //           county_name: item.county_name.S,
  //           percent: item.percent.S,
  //         });
  //       });
  //       setDisabilityRowData(raceData);
  //     });
  // };
  const fetchGenderData = () => {
    return fetch(gender_url, {
      // headers: {
      //   "x-api-key": "NUQg1g8vg07dnS30ujuHi2TCGjloWdai7X9tPCy0",
      // },
    })
      .then((res) => res.json())
      .then((data) => {
        const items = data.Items || []; // Check if "Items" array exists
        let genderData = [];
        items.map((item) => {
          genderData.push({
            id: item.row_id.N,
            row_id: item.row_id.N,
            company_code: item.company_code.S,
            date: item.date.S,
            company_name: item.company_name.S,
            count: item.count.N,
            county_id: item.county_id.N,
            county_name: item.county_name.S,
            gender: item.gender.S,
            percent: item.percent.S,
            geo_id: item.geo_id.S,
          });
        });
        setGenderRowData(genderData);
      });
  };
  const fetchEthnicityData = () => {
    return fetch(ethnicity_url, {
      // headers: {
      //   "x-api-key": "NUQg1g8vg07dnS30ujuHi2TCGjloWdai7X9tPCy0",
      // },
    })
      .then((res) => res.json())
      .then((data) => {
        const items = data.Items || []; // Check if "Items" array exists
        let ethnicityData = [];
        items.map((item) => {
          ethnicityData.push({
            id: item.row_id.N,
            row_id: item.row_id.N,
            company_code: item.company_code.S,
            date: item.date.S,
            company_name: item.company_name.S,
            count: item.count.N,
            county_id: item.county_id.N,
            county_name: item.county_name.S,
            ethnicity: item.ethnicity.S,
            percent: item.percent.S,
            geo_id: item.geo_id.S,
          });
        });
        setEthnicityRowData(ethnicityData);
      });
  };
  const fetchRaceData = () => {
    return fetch(race_url, {
      // headers: {
      //   "x-api-key": "NUQg1g8vg07dnS30ujuHi2TCGjloWdai7X9tPCy0",
      // },
    })
      .then((res) => res.json())
      .then((data) => {
        const items = data.Items || []; // Check if "Items" array exists
        let raceData = [];
        items.map((item) => {
          raceData.push({
            id: item.row_id.N,
            row_id: item.row_id.N,
            company_code: item.company_code.S,
            date: item.date.S,
            company_name: item.company_name.S,
            count: item.count.N,
            county_id: item.county_id.N,
            county_name: item.county_name.S,
            race: item.race.S,
            percent: item.percent.S,
            geo_id: item.geo_id.S,
          });
        });
        setRaceRowData(raceData);
      });
  };
  const fetchVeteranData = () => {
    return fetch(veteran_url, {
      // headers: {
      //   "x-api-key": "NUQg1g8vg07dnS30ujuHi2TCGjloWdai7X9tPCy0",
      // },
    })
      .then((res) => res.json())
      .then((data) => {
        const items = data.Items || []; // Check if "Items" array exists
        let raceData = [];
        items.map((item) => {
          raceData.push({
            id: item.row_id.N,
            row_id: item.row_id.N,
            company_code: item.company_code.S,
            date: item.date.S,
            company_name: item.company_name.S,
            count: item.count.N,
            county_id: item.county_id.N,
            county_name: item.county_name.S,
            is_veteran: item.is_veteran.S,
            percent: item.percent.S,
            geo_id: item.geo_id.S,
          });
        });
        setVeteranRowData(raceData);
      });
  };
  const fetchDisabilityData = () => {
    return fetch(disability_url, {
      // headers: {
      //   "x-api-key": "NUQg1g8vg07dnS30ujuHi2TCGjloWdai7X9tPCy0",
      // },
    })
      .then((res) => res.json())
      .then((data) => {
        const items = data.Items || []; // Check if "Items" array exists
        let raceData = [];
        items.map((item) => {
          raceData.push({
            id: item.row_id.N,
            row_id: item.row_id.N,
            company_code: item.company_code.S,
            date: item.date.S,
            company_name: item.company_name.S,
            count: item.count.N,
            county_id: item.county_id.N,
            county_name: item.county_name.S,
            percent: item.percent.S,
            is_disable: item.is_disable.S,
            geo_id: item.geo_id.S,
          });
        });
        setDisabilityRowData(raceData);
      });
  };


  const saveDataByRow = async (fetchData, payload, url) => {
    const { data } = await axios.post(url, payload
      //   , {
      //   headers: {
      //     "x-api-key": "NUQg1g8vg07dnS30ujuHi2TCGjloWdai7X9tPCy0",
      //   },
      // }
    )
      ;
    fetchData();
  };

  const handleSubmit = (name, url, fetchData) => {
    switch (name) {
      case "gender":
        genderRowData.forEach((item) => {
          saveDataByRow(fetchData, item, url);
        });
        break;
      case "ethnicity":
        ethnicityRowData.forEach((item) => {
          saveDataByRow(fetchData, item, url);
        });
        break;
      case "race":
        raceRowData.forEach((item) => {
          saveDataByRow(fetchData, item, url);
        });
        break;
      case "veteran":
        veteranRowData.forEach((item) => {
          saveDataByRow(fetchData, item, url);
        });
        break;
      case "disability":
        disabilityRowData.forEach((item) => {
          saveDataByRow(fetchData, item, url);
        });
        break;
    }

  };
  const handleChange = (event) => {
    setDataType(event.target.value);
  };
  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const processGenderRowUpdate = (newRow) => {
    setGenderRowData(
      genderRowData.map((row) => (row.id === newRow.id ? newRow : row))
    );
    return newRow;
  };
  const processEthnicityRowUpdate = (newRow) => {
    setEthnicityRowData(
      ethnicityRowData.map((row) => (row.id === newRow.id ? newRow : row))
    );
    return newRow;
  };

  const processRaceRowUpdate = (newRow) => {
    setRaceRowData(
      raceRowData.map((row) => (row.id === newRow.id ? newRow : row))
    );
    return newRow;
  };

  const processVeteranRowUpdate = (newRow) => {
    setVeteranRowData(
      veteranRowData.map((row) => (row.id === newRow.id ? newRow : row))
    );
    return newRow;
  };

  const processDisabilityRowUpdate = (newRow) => {
    setDisabilityRowData(
      disabilityRowData.map((row) => (row.id === newRow.id ? newRow : row))
    );
    return newRow;
  };

  return (
    <div style={{ height: 300, width: "90%" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginLeft: "30%",
          flexDirection: "column",
          mt: "4em"
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "1em",
            padding: "1em",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            mt: "2em",
          }}
        >
          <Typography
            variant="h4"
            marked="center"
            component="h2"
            sx={{ mb: 4 }}
          >
            Upload Data
          </Typography>
        </Box>
        {/* Diversity- Gender */}
        <Box sx={{ minWidth: 120 }}>
          <FormControl  >
            <InputLabel id="demo-simple-select-label">Data</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={dataType}
              label="Age"
              onChange={handleChange}
              sx={{ backgroundColor: secondaryColor.light }}
            >
              <MenuItem value={"Gender"}>Gender</MenuItem>
              <MenuItem value={"Ethnicity"}>Ethnicity </MenuItem>
              <MenuItem value={"Race"}>Race </MenuItem>
              <MenuItem value={"Veteran"}>Veteran  </MenuItem>
              <MenuItem value={"Disability"}>Disability  </MenuItem>
            </Select>
          </FormControl>
        </Box>
        {dataType === "Gender" && <Box>
          <Typography variant="h6" component="span" sx={{ mt: 2 }}>
            Diversity- Gender
          </Typography>
          <DataGrid
            sx={{ backgroundColor: secondaryColor.light }}
            rows={genderRowData}
            columns={gender_columns}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processGenderRowUpdate}
            autoHeight
            disableSelectionOnClick
            editable
          />
          <Button
            sx={{
              backgroundColor: secondaryColor.dark,
              color: "white",
              "&:hover": {
                color: "black",
              },
              mt: 2,
            }}
            onClick={() => handleSubmit("gender", gender_url, fetchGenderData)}
          >
            Submit
          </Button>
        </Box>}
        {/* Diversity- Ethnicity */}
        {dataType === "Ethnicity" && <Box>
          <Typography variant="h6" component="span" sx={{ mt: 2 }}>
            Diversity- Ethnicity
          </Typography>
          <DataGrid
            sx={{ backgroundColor: secondaryColor.light }}
            rows={ethnicityRowData}
            columns={ethnicity_columns}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processEthnicityRowUpdate}
            autoHeight
            disableSelectionOnClick
            editable
          />
          <Button
            sx={{
              backgroundColor: "#71502f",
              color: "white",
              "&:hover": {
                color: "black",
              },
              mt: 2,
            }}
            onClick={() =>
              handleSubmit("ethnicity", ethnicity_url, fetchEthnicityData)
            }
          >
            Submit
          </Button>
        </Box>}
        {/* Diversity- Race */}
        {dataType === "Race" && <Box>
          <Typography variant="h6" component="span" sx={{ mt: 2 }}>
            Diversity- Race
          </Typography>
          <DataGrid
            sx={{ backgroundColor: secondaryColor.light }}
            rows={raceRowData}
            columns={race_columns}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRaceRowUpdate}
            autoHeight
            disableSelectionOnClick
            editable
          />
          <Button
            sx={{
              backgroundColor: "#71502f",
              color: "white",
              "&:hover": {
                color: "black",
              },
              mt: 2,
            }}
            onClick={() => handleSubmit("race", race_url, fetchRaceData)}
          >
            Submit
          </Button>
        </Box>}
        {dataType === "Veteran" && <Box>
          <Typography variant="h6" component="span" sx={{ mt: 2 }}>
            Diversity- Veteran
          </Typography>
          <DataGrid
            sx={{ backgroundColor: secondaryColor.light }}
            rows={veteranRowData}
            columns={veteran_columns}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processVeteranRowUpdate}
            autoHeight
            disableSelectionOnClick
            editable
          />
          <Button
            sx={{
              backgroundColor: "#71502f",
              color: "white",
              "&:hover": {
                color: "black",
              },
              mt: 2,
            }}
            onClick={() =>
              handleSubmit("veteran", veteran_url, fetchVeteranData)
            }
          >
            Submit
          </Button>
        </Box>}
        {dataType === "Disability" && <Box>
          <Typography variant="h6" component="span" sx={{ mt: 2 }}>
            Diversity- Disability
          </Typography>
          <DataGrid
            sx={{ backgroundColor: secondaryColor.light }}
            rows={disabilityRowData}
            columns={disability_columns}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processDisabilityRowUpdate}
            autoHeight
            disableSelectionOnClick
            editable
          />
          <Button
            sx={{
              backgroundColor: "#71502f",
              color: "white",
              "&:hover": {
                color: "black",
              },
              mt: 2,
            }}
            onClick={() =>
              handleSubmit("disability", disability_url, fetchDisabilityData)
            }
          >
            Submit
          </Button>
        </Box>}
      </Box>
    </div>
  );
}
