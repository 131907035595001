export const data = [ 
    {
      "group": "Belongingness",
      "value": 4.1
    },
    {
      "group": "Belongingness",
      "value": 5.0
    },
    {
      "group": "Belongingness",
      "value": 3.5
    },
    {
      "group": "Belongingness",
      "value": 2.8
    },
    {
      "group": "Belongingness",
      "value": 3.7
    },
    {
      "group": "Belongingness",
      "value": 4.0
    },
    {
      "group": "Belongingness",
      "value": 4.6
    },
    {
      "group": "Belongingness",
      "value": 4.5
    },
    {
      "group": "Belongingness",
      "value": 2.9
    },
    {
      "group": "Belongingness",
      "value": 2.4
    },
    {
      "group": "Uniqueness",
      "value": 4.0
    },
    {
      "group": "Uniqueness",
      "value": 2.9
    },
    {
      "group": "Uniqueness",
      "value": 3.7
    },
    {
      "group": "Uniqueness",
      "value": 4.8
    },
    {
      "group": "Uniqueness",
      "value": 5.0
    },
    {
      "group": "Uniqueness",
      "value": 4.4
    },
    {
      "group": "Uniqueness",
      "value": 4.6
    },
    {
      "group": "Uniqueness",
      "value": 3.5
    },
    {
      "group": "Uniqueness",
      "value": 5.0
    },
    {
      "group": "Uniqueness",
      "value": 2.8
    },
    ]