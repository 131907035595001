import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import videoFile from '../brave_spaces_display.mp4';
const MainBannerLayoutRoot = styled('section')(({ theme }) => ({
  color: theme.palette.common.white,
  position: 'relative',
  display: 'block',
  alignItems: 'center',
  [theme.breakpoints.up('xs')]: {
    // height: '120vh',
    minHeight: 200,
    maxHeight: 1300,
    paddingTop: "5em"
  },
}));

const Background = styled(Box)(({ opacity }) => ({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  zIndex: -2,
  opacity: opacity,
}));

function MainBannerLayout(props) {
  const { sxBackground, children, backgroundOpacity } = props;

  return (
    <MainBannerLayoutRoot>
      <video src={videoFile} autoPlay loop muted style={{ objectFit: 'contain', width: '100%', height: '100%',display:"block" }}>
        Your browser does not support the video tag.
      </video>
    </MainBannerLayoutRoot>
  );
}

MainBannerLayout.propTypes = {
  children: PropTypes.node,
  sxBackground: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool]),
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
  backgroundOpacity: PropTypes.number,
};

MainBannerLayout.defaultProps = {
  backgroundOpacity: 0.6, // Default opacity value of 0.5 (adjust as needed)
};

export default MainBannerLayout;
