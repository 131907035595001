import React, { useContext, useEffect, useState, createRef } from 'react'
import DashboardContext from '../../context';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import axios from "axios";
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useTheme } from '@mui/material/styles';

import {
    GridRowModes,
    DataGrid,
    GridToolbarContainer,
    GridActionsCellItem,
    GridRowEditStopReasons,
} from '@mui/x-data-grid';
import {
    randomCreatedDate,
    randomTraderName,
    randomId,
    randomArrayItem,
} from '@mui/x-data-grid-generator';

const roles = ['Market', 'Finance', 'Development'];
const randomRole = () => {
    return randomArrayItem(roles);
};

const initialRows = [
    // {
    //     id: randomId(),
    //     name: randomTraderName(),
    //     age: 25,
    //     joinDate: randomCreatedDate(),
    //     role: randomRole(),
    // }
    {
        id: 33,
        row_id: 33,
        company_id: 43,
        project: "Women in Leadership",
        company_name: "item.company_name.S",
        specific: "item.specific.S",
        updates: "item.updates.S",
        measurable: "item.measurable.S",
        attainable: "item.attainable.S",
        time_based: "item.time_based.S",
        data_of_logic: "item.data_of_logic.S",
        relevant: "item.relevant.S"
    }
];
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
function EditToolbar(props) {
    const { setRows, setRowModesModel } = props;
    const [success, setSuccess] = React.useState(false);

    const snackbarOpen = () => {
        setSuccess(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSuccess(false);
    };

    console.log(props)
    const handleClick = () => {
        const id = Math.random().toString();
        const row_id = id;
        setRows((oldRows) => [...oldRows, { id, row_id, specific: '', company_code: props.ctx.companyData.company_code, company_name: '', project: '', updates: '', specific: '', measurable: '', attainable: '', time_based: '', data_of_logic: '', relevant: '', company_id: "2", isNew: true }]);
        setRowModesModel((oldModel) => ({
            ...oldModel,
            [id]: { mode: GridRowModes.Edit, fieldToFocus: 'project' },
        }));
    };

    return (
        <GridToolbarContainer>
            <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
                Add record
            </Button>
        </GridToolbarContainer>
    );
}

export default function FullFeaturedCrudGrid() {
    const theme = useTheme();
    const primaryColor = theme.palette.primary;
    const secondaryColor = theme.palette.secondary;
    const ctx = useContext(DashboardContext);
    console.log(ctx);
    const [rows, setRows] = React.useState(initialRows);
    const [isSubmittionSuccessfull, setIsSubmittionSuccessfull] = React.useState(false);
    const [showSnackbar, setShowSnackbar] = React.useState(false);
    const [rowModesModel, setRowModesModel] = React.useState({});
    const url = `https://ej0t9qbga6.execute-api.us-west-1.amazonaws.com/prod/blueprint?company_code=${ctx.companyData.company_code}`;

    const fetchInfo = () => {
        return fetch(url, {
            headers: {
                'x-api-key': 'NUQg1g8vg07dnS30ujuHi2TCGjloWdai7X9tPCy0'
            }
        })
            .then((res) => res.json())
            .then((data) => {
                const items = data.Items || []; // Check if "Items" array exists
                let blueprintData = [];
                items.map((item) => {
                    blueprintData.push({
                        id: item.row_id.N,
                        row_id: item.row_id.N,
                        company_code: item.company_code.S,
                        project_title: item.project_title.S,
                        company_name: item.company_name.S,
                        specific: item.specific.S,
                        updates: item.updates.S,
                        measurable: item.measurable.S,
                        attainable: item.attainable.S,
                        time_based: item.time_based.S,
                        data_of_logic: item.data_of_logic.S,
                        relevant: item.relevant.S,
                        project_description: item.project_description.S,
                        status: item.status.S,
                        created_date: item.created_date.S,
                        completion_date: item.completion_date.S,
                        assigned_to: item.assigned_to.S,
                        // geo_id: item.geo_id.S
                    })
                });
                setRows(blueprintData);
            });
    };
    // const postBlueprint = async (payload) => {
    //     const { data } = await axios.post(url, payload, {
    //         headers: {
    //             'x-api-key': 'NUQg1g8vg07dnS30ujuHi2TCGjloWdai7X9tPCy0'
    //         }
    //     }
    //     )
    // };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowSnackbar(false);
    };
    const postBlueprint = async (payloads) => {

        return await axios.post(url, payloads)
            .catch(error => {
                console.error('Error:', error);
                setIsSubmittionSuccessfull(false);
                setShowSnackbar(true);
            })
    };

    // const deleteBlueprint = async (partitionKey) => {
    //     try {
    //         const response = await axios.delete(url, {
    //             headers: {
    //                 'x-api-key': 'NUQg1g8vg07dnS30ujuHi2TCGjloWdai7X9tPCy0'
    //             },
    //             data: { partition_key_value: partitionKey } // Pass the partition key value in the request body or query parameter
    //         });
    //         console.log('Row deleted successfully', response.data);
    //     } catch (error) {
    //         console.error('Error deleting row:', error);
    //     }
    // };

    // const handleSubmit =async () => {
    //     rows.forEach((item) => {
    //         postBlueprint(item);
    //     });
    //     await fetchInfo();
    // };

    const handleSubmit = async () => {
        try {
            const response = await postBlueprint(rows);
            console.log('Success:', response);
            let isSuccess = response.status === 200 ? true : false;
            setIsSubmittionSuccessfull(isSuccess);
            setShowSnackbar(true);
            await fetchInfo();
        } catch (error) {
            console.error('Error:', error);
            setIsSubmittionSuccessfull(false);
            setShowSnackbar(true);
        }
    };

    React.useEffect(() => {
        fetchInfo();
    }, []);

    const handleRowEditStop = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
            event.defaultMuiPrevented = true;
        }
    };

    const handleEditClick = (id) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    };

    const handleSaveClick = (id) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } },);
        // rows.forEach( (item) => {
        //     if (item.id === id) { // Replace 'desiredRowId' with the row_id you want to match
        //        console.log("row id"+item.id+"id passed"+id);
        //        console.log(item);
        //        postBlueprint(item);
        //     }
        //   });
        // //    fetchInfo();
    };

    const handleDeleteClick = (id) => () => {
        setRows(rows.filter((row) => row.id !== id));
        // rows.forEach( (item) => {
        //     if (item.id === id) { // Replace 'desiredRowId' with the row_id you want to match
        //         deleteBlueprint(item);
        //     }
        //   });
        //    fetchInfo();
    };

    const handleCancelClick = (id) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });

        const editedRow = rows.find((row) => row.id === id);
        if (editedRow.isNew) {
            setRows(rows.filter((row) => row.id !== id));
        }
    };

    const processRowUpdate = (newRow) => {
        const updatedRow = { ...newRow, isNew: false };
        setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
        return updatedRow;
    };

    const handleRowModesModelChange = (newRowModesModel) => {
        setRowModesModel(newRowModesModel);
    };

    const columns = [
        { field: 'project_title', headerName: 'Project Title', width: 115, headerClassName: 'custom-header-row', headerAlign: 'center', editable: true },
        { field: 'project_description', headerName: 'Project Description', width: 180, headerClassName: 'custom-header-row', headerAlign: 'center', editable: true },
        //{ field: 'data_of_logic', headerName: 'Data of Logic (WHY)', width: 115, headerClassName: 'custom-header-row', headerAlign: 'center', editable: true },
        { field: 'specific', headerName: '(S)Specific', width: 115, headerClassName: 'custom-header-row', headerAlign: 'center', editable: true },
        { field: 'measurable', headerName: '(M)Measurable', width: 115, headerClassName: 'custom-header-row', headerAlign: 'center', editable: true },
        { field: 'attainable', headerName: '(A)Attainable', width: 115, headerClassName: 'custom-header-row', headerAlign: 'center', editable: true },
        { field: 'relevant', headerName: '(R)Relavant', width: 115, headerClassName: 'custom-header-row', headerAlign: 'center', editable: true },
        { field: 'time_based', headerName: '(T)Time-Based', width: 115, headerClassName: 'custom-header-row', headerAlign: 'center', editable: true },
        // { field: 'status', headerName: 'Status', width: 150, headerClassName: 'custom-header-row', headerAlign: 'center', editable: true },
        // { field: 'updates', headerName: 'Updates', width: 150, headerClassName: 'custom-header-row', headerAlign: 'center', editable: true },
        // { field: 'created_date', headerName: 'Created On', width: 150, headerClassName: 'custom-header-row', headerAlign: 'center', editable: true },
        // { field: 'completion_date', headerName: 'Completed On', width: 150, headerClassName: 'custom-header-row', headerAlign: 'center', editable: true },
        // { field: 'assigned_to', headerName: 'Assigned To', width: 150, headerClassName: 'custom-header-row', headerAlign: 'center', editable: true },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 100,
            headerClassName: 'custom-header-row',
            cellClassName: 'actions',
            getActions: ({ id }) => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            icon={<SaveIcon />}
                            label="Save"
                            sx={{
                                color: 'primary.main',
                            }}
                            onClick={handleSaveClick(id)}
                        />,
                        <GridActionsCellItem
                            icon={<CancelIcon />}
                            label="Cancel"
                            className="textPrimary"
                            onClick={handleCancelClick(id)}
                            color="inherit"
                        />,
                    ];
                }

                return [
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        label="Edit"
                        className="textPrimary"
                        onClick={handleEditClick(id)}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Delete"
                        onClick={handleDeleteClick(id)}
                        color="inherit"
                    />,
                ];
            },
        },
    ];

    return (
        // <Box
        //     sx={{
        //         height: 500,
        //         width: '100%',
        //         '& .actions': {
        //             color: 'text.secondary',
        //         },
        //         '& .textPrimary': {
        //             color: 'text.primary',
        //         },
        //     }}
        // >
        <>
            <DataGrid
            sx={{backgroundColor:secondaryColor.light}}
                rows={rows}
                columns={columns}
                editMode="row"
                rowModesModel={rowModesModel}
                onRowModesModelChange={handleRowModesModelChange}
                onRowEditStop={handleRowEditStop}
                processRowUpdate={processRowUpdate}
                slots={{
                    toolbar: EditToolbar,
                }}
                slotProps={{
                    toolbar: { setRows, setRowModesModel, ctx },
                }}
            />
            <Button
                sx={{
                    backgroundColor: '#71502f',
                    color: 'white',
                    '&:hover': {
                        color: 'black',
                    },
                    mt: 2,

                }}
                onClick={handleSubmit}
            >
                Submit
            </Button>
            <Snackbar open={showSnackbar && !isSubmittionSuccessfull} autoHideDuration={6000} onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity="error" sx={{ width: '100%' }}>
                    Error While submittion!
                </Alert>
            </Snackbar>
            <Snackbar open={showSnackbar && isSubmittionSuccessfull} autoHideDuration={6000} onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
                    Successfully Submitted!
                </Alert>
            </Snackbar>
        </>
    );
}
