import { useTheme } from "@mui/material";
import { ResponsiveBar } from '@nivo/bar'

const BarGraph = (props) => {
    const theme = useTheme();
    const primaryColor = theme.palette.primary;
    const secondaryColor = theme.palette.secondary;
    return (
        <ResponsiveBar
            
            data={props.data}
            keys={props.legendKeys}
            indexBy={props.indexBy}
            margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
            padding={0.3}
            groupMode="grouped"
            valueScale={{ type: 'linear' }}
            indexScale={{ type: 'band', round: true }}
            colors={{ scheme: 'brown_blueGreen' }}
            defs={[
                {
                    id: 'dots',
                    type: 'patternDots',
                    background: 'inherit',
                    color: '#38bcb2',
                    size: 4,
                    padding: 1,
                    stagger: true
                },
                {
                    id: 'lines',
                    type: 'patternLines',
                    background: 'inherit',
                    color: '#eed312',
                    rotation: -45,
                    lineWidth: 6,
                    spacing: 10
                }
            ]}
            // fill={[
            //     {
            //         match: {
            //             id: 'fries'
            //         },
            //         id: 'dots'
            //     },
            //     {
            //         match: {
            //             id: 'sandwich'
            //         },
            //         id: 'lines'
            //     }
            // ]}
            borderRadius={2}
            borderWidth={2}
            borderColor={{ theme: 'labels.text.fill' }}
            axisTop={null}
            axisRight={null}
            axisBottom={props.axisBottom}
            //     {
            //     tickSize: 5,
            //     tickPadding: 5,
            //     tickRotation: 0,
            //     legend: 'gender category',
            //     legendPosition: 'middle',
            //     legendOffset: 32
            // }
            // }
            axisLeft={props.axisLeft}
            //     {
            //     tickSize: 5,
            //     tickPadding: 5,
            //     tickRotation: 0,
            //     legend: 'gender percentage',
            //     legendPosition: 'middle',
            //     legendOffset: -40
            // }}
            enableGridX={true}
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor={secondaryColor.light}
            // labelTextColor={{
            //     from: 'color',
            //     modifiers: [
            //         [
            //             'darker',
            //             1.6
            //         ]
            //     ]
            // }}
            legends={[
                {
                    dataFrom: 'keys',
                    anchor: 'top-right',
                    direction: 'column',
                    justify: false,
                    translateX: 120,
                    translateY: 0,
                    itemWidth: 100,
                    itemHeight: 50,
                    itemsSpacing: 2,
                    symbolSize: 40,
                    itemDirection: 'left-to-right'
                }
            ]}
            role="application"
            ariaLabel="bar chart"
            barAriaLabel={e => e.id + ": " + e.formattedValue + " in country: " + e.indexValue}
        />
    )
}

export default BarGraph;