import React from "react";
import { Box, Divider, Stack, ButtonBase, Button } from "@mui/material";
import Typography from "../../components/Typography";
import { Outlet } from "react-router-dom";
import TopNav from "./TopNav";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import AllInclusiveIcon from "@mui/icons-material/AllInclusive";
import SettingsSuggestOutlinedIcon from "@mui/icons-material/SettingsSuggestOutlined";
import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
import KeyboardDoubleArrowRightOutlinedIcon from "@mui/icons-material/KeyboardDoubleArrowRightOutlined";
import CabinIcon from "@mui/icons-material/Cabin";
import AutoGraphOutlinedIcon from "@mui/icons-material/AutoGraphOutlined";
import { useNavigate } from "react-router-dom";
import { withAuthenticator } from "@aws-amplify/ui-react";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { useEffect, useState, useContext } from "react";
import { Auth, Hub } from "aws-amplify";
import DashboardContext from "../../context";
import axios from "axios";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { driver } from "driver.js";
import "driver.js/dist/driver.css";
import "../styling/tour.css";

function Sidenav() {
  const context = useContext(DashboardContext);
  const [loading, setLoading] = useState(true);
  const [activeSidenavSelection, setActiveSidenavSelection] =
    useState("diversity");

  const navigate = useNavigate();
  const isDesktop = useMediaQuery("(min-width: 768px)");
  const theme = useTheme();
  const primaryColor = theme.palette.primary;
  const secondaryColor = theme.palette.secondary;
  useEffect(() => {
    async function fetchUserDetails() {
      try {
        const user = await Auth.currentAuthenticatedUser();
        const company_code = user.attributes["custom:code_company"];

        fetchCountyData();
        fetchCompanyData(company_code);
        fetchGenderData(company_code);
        fetchEthnicityData(company_code);
        fetchRaceData(company_code);
        fetchVeteranData(company_code);
        fetchDisabilityData(company_code);
        fetchstats();
        driverObj.drive();
      } catch (error) {
        console.error("Error fetching user details:", error);
      }
    }

    fetchUserDetails();
  }, []);

  const driverObj = driver({
    popoverClass: "driverjs-theme",
    showProgress: true,
    steps: [
      {
        popover: {
          title: "Welcome to Brave Space! ",
          description:
            "<img src='/brave_spaces_logo.png' style='width: 100%;margin-top: 1rem;' /><span style='display: block; margin-top: 1rem; '> This tour is designed to acquaint you with the various features of Brave Spaces, a SaaS application, and guide you through the initial setup process. Welcome aboard!</span>",
        },
      },
      {
        element: "#diagnostic-diversity",
        popover: {
          title: "Diversity Analysis",
          description:
            " Explore and compare the diversity within your organization with the demographics of your county. Assess factors such as gender, ethnicity, race, disability, and veteran status.",
          side: "left",
          align: "start",
        },
      },
      {
        element: "#diagnostic-inclusion",
        popover: {
          title: "Inclusion Analysis",
          description:
            "Evaluate the inclusivity of your workspace through a specialized inclusion survey designed for employees. Gain insights into the level of inclusion within your organization.",
          side: "left",
          align: "start",
        },
      },
      {
        element: "#blueprint",
        popover: {
          title: "Blueprint for Success",
          description:
            " Develop strategic goals to address diversity and inclusion gaps within your organization. Use this blueprint to guide your efforts towards creating a more inclusive environment.",
          side: "left",
          align: "start",
        },
      },
      {
        element: "#success-tracker",
        popover: {
          title: "Track your Progress",
          description:
            "Monitor your organization's progress in fostering diversity and inclusion by analyzing historical data through intuitive charts and graphs. Keep track of your journey towards a more inclusive workplace.",
          side: "left",
          align: "start",
        },
      },
      {
        element: "#upload-data",
        popover: {
          title: "Upload Diversity Data",
          description:
            "Easily upload and analyze diversity data from your organization to gain valuable insights and inform decision-making processes related to diversity and inclusion initiatives.",
          side: "left",
          align: "start",
        },
      },
      // { element: '#logout-button', popover: { title: 'Logout Button', description: 'Click on \"LOGOUT\" button to sign out from the breave spaces application.', side: "bottom", align: 'start' } },
      {
        popover: {
          title: "Tour Ended! ",
          description:
            "<img src='/brave_spaces_logo.png' style='width: 100%;margin-top: 1rem;' /><span style='display: block; margin-top: 1rem; '> Thank you for completing the Brave Spaces tour! You can revisit this tour at any time by clicking the \"Open Tour\" button.</span>",
        },
      },
    ],
  });
  const fetchCountyData = () => {
    const county_url =
      "https://ej0t9qbga6.execute-api.us-west-1.amazonaws.com/prod/censusdata";

    axios
      .get(county_url)
      .then((resp) => {
        // const items = resp.data.Items || []; // Check if "Items" array exists

        let countyData = [];
        resp.data.Items.map((item) => {
          countyData.push({
            race_haw_pac: item.race_haw_pac ? item.race_haw_pac.S : "0",
            sex_male_total: item.sex_male_total ? item.sex_male_total.S : "0",
            disability: item.disability ? item.disability.S : "0",
            company_or_county: item.company_or_county
              ? item.company_or_county.S
              : "0",
            county: item.county ? item.county.S : "0",
            geo_id: item.geo_id ? item.geo_id.S : "0",
            race_others: item.race_others ? item.race_others.S : "0",
            race_asian: item.race_asian ? item.race_asian.S : "0",
            name: item.name ? item.name.S : "0",
            race_american_indian: item.race_american_indian
              ? item.race_american_indian.S
              : "0",
            total_vet: item.total_vet ? item.total_vet.S : "0",
            state: item.state ? item.state.S : "0",
            veteran: item.veteran ? item.veteran.S : "0",
            eth_hisp: item.eth_hisp ? item.eth_hisp.S : "0",
            total_population: item.total_population
              ? item.total_population.S
              : "0",
            total_race: item.total_race ? item.total_race.S : "0",
            race_black: item.race_black ? item.race_black.S : "0",
            race_white: item.race_white ? item.race_white.S : "0",
            total_eth: item.total_eth ? item.total_eth.S : "0",
            eth_not_hisp: item.eth_not_hisp ? item.eth_not_hisp.S : "0",
            non_veteran: item.non_veteran ? item.non_veteran.S : "0",
            sex_female_total: item.sex_female_total
              ? item.sex_female_total.S
              : "0",
          });
        });
        console.log(countyData);
        context.setCensusData(countyData);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const fetchGenderData = (company_code) => {
    const gender_url = `https://ej0t9qbga6.execute-api.us-west-1.amazonaws.com/prod/upload/gender?company_code=${company_code}`;
    axios
      .get(gender_url)
      .then((data) => {
        console.log("gender datat ", data);
        const items = data.data.Items || []; // Check if "Items" array exists
        let genderData = [];
        items.map((item) => {
          genderData.push({
            id: item.row_id.N,
            row_id: item.row_id.N,
            company_code: item.company_code.S,
            date: item.date.S,
            company_name: item.company_name.S,
            count: item.count.N,
            county_id: item.county_id.N,
            county_name: item.county_name.S,
            gender: item.gender.S,
            percent: item.percent.S,
            geo_id: item.geo_id.S,
          });
        });
        context.setGenderData(genderData);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const fetchEthnicityData = (company_code) => {
    const ethnicity_url = `https://ej0t9qbga6.execute-api.us-west-1.amazonaws.com/prod/upload/ethnicity?company_code=${company_code}`;
    return axios
      .get(ethnicity_url)
      .then((data) => {
        const items = data.data.Items || []; // Check if "Items" array exists
        let ethnicityData = [];
        items.map((item) => {
          ethnicityData.push({
            id: item.row_id.N,
            row_id: item.row_id.N,
            company_code: item.company_code.S,
            date: item.date.S,
            company_name: item.company_name.S,
            count: item.count.N,
            county_id: item.county_id.N,
            county_name: item.county_name.S,
            ethnicity: item.ethnicity.S,
            percent: item.percent.S,
            geo_id: item.geo_id.S,
          });
        });
        context.setEthnicityData(ethnicityData);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const fetchRaceData = (company_code) => {
    const race_url = `https://ej0t9qbga6.execute-api.us-west-1.amazonaws.com/prod/upload/race?company_code=${company_code}`;
    axios
      .get(race_url)
      .then((data) => {
        const items = data.data.Items || []; // Check if "Items" array exists
        let raceData = [];
        items.map((item) => {
          raceData.push({
            id: item.row_id.N,
            row_id: item.row_id.N,
            company_code: item.company_code.S,
            date: item.date.S,
            company_name: item.company_name.S,
            count: item.count.N,
            county_id: item.county_id.N,
            county_name: item.county_name.S,
            race: item.race.S,
            percent: item.percent.S,
            geo_id: item.geo_id.S,
          });
        });
        context.setRaceData(raceData);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const fetchVeteranData = (company_code) => {
    const veteran_url = `https://ej0t9qbga6.execute-api.us-west-1.amazonaws.com/prod/upload/veteran?company_code=${company_code}`;
    axios
      .get(veteran_url)
      .then((data) => {
        const items = data.data.Items || []; // Check if "Items" array exists
        let raceData = [];
        items.map((item) => {
          raceData.push({
            id: item.row_id.N,
            row_id: item.row_id.N,
            company_code: item.company_code.S,
            date: item.date.S,
            company_name: item.company_name.S,
            count: item.count.N,
            county_id: item.county_id.N,
            county_name: item.county_name.S,
            is_veteran: item.is_veteran.S,
            percent: item.percent.S,
            geo_id: item.geo_id.S,
          });
        });
        context.setVeteranData(raceData);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const fetchDisabilityData = (company_code) => {
    const disability_url = `https://ej0t9qbga6.execute-api.us-west-1.amazonaws.com/prod/upload/disability?company_code=${company_code}`;
    axios
      .get(disability_url)
      .then((data) => {
        const items = data.data.Items || []; // Check if "Items" array exists
        let raceData = [];
        items.map((item) => {
          raceData.push({
            id: item.row_id.N,
            row_id: item.row_id.N,
            company_code: item.company_code.S,
            date: item.date.S,
            company_name: item.company_name.S,
            count: item.count.N,
            county_id: item.county_id.N,
            county_name: item.county_name.S,
            percent: item.percent.S,
            is_disable: item.is_disable.S,
            geo_id: item.geo_id.S,
          });
        });
        context.setDisableData(raceData);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const fetchCompanyData = (company_code) => {
    const company_url = `https://ej0t9qbga6.execute-api.us-west-1.amazonaws.com/prod/upload/company?company_code=${company_code}`;
    axios
      .get(company_url)
      .then((data) => {
        console.log(data.data);
        let companyData = {
          company_code: data.data.Item.company_code.S,
          company_name: data.data.Item.company_name.S,
          county: data.data.Item.county.S,
          geo_id: data.data.Item.geo_id.S,
          state: data.data.Item.state.S,
        };
        // console.log("setCompanyData", context.setCompanyData)
        context.setCompanyData(companyData);
        // console.log("company data", companyData)
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const fetchstats = () => {
    const stats_url = `https://ej0t9qbga6.execute-api.us-west-1.amazonaws.com/prod/upload/statistics`;
    axios
      .get(stats_url)
      .then((data) => {
        context.setStatsData(data);
        //return pvaluedata;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <React.Fragment>
      <TopNav />
      <div style={{ display: "flex", paddingTop: "5em" }}>
        {isDesktop && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
              position: "fixed",
              width: "300px",
            }}
          >
            <Box sx={{ p: 3, backgroundColor: "#71502f" }}>
              <Box
                sx={{
                  alignItems: "center",
                  backgroundColor: "rgba(255, 247, 237, 0.1)",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  borderRadius: 2,
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "center",
                  mt: 2,
                  p: "12px",
                  color: "black",
                  transition: "all 0.9s",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <Box
                    sx={{
                      width: 80,
                      height: 80,
                      borderRadius: "50%",
                      backgroundColor: "white",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {!loading && (
                      <Box
                        component="img"
                        src={
                          context.companyData.company_code === "EI01"
                            ? "/ABC university.png"
                            : context.companyData.company_code === "BS01"
                            ? "/brave_spaces_logo.png"
                            : "/foodbank.png"
                        }
                        alt={context.companyData.company_name}
                        sx={{
                          width: 60,
                          filter:
                            context.companyData.company_code === "EI01"
                              ? "invert(0)"
                              : "invert(0)",
                          // sacle:context.companyData.company_code === 'EI01' ?"2":"1"
                        }}
                        /*onClick={() => { navigate(userGroup === 'education' ? '/education' : '/foodbank') }}*/
                        onClick={() => {
                          navigate("/foodbank");
                        }}
                      />
                    )}
                  </Box>

                  <Typography
                    color="white"
                    variant="body2"
                    alignItems="center"
                    fontSize="1em"
                    sx={{
                      marginTop: "1em",
                    }}
                  >
                    {/* {!loading ? context.companyData.company_name : ""} */}
                    {!loading ? (
                      <div
                        // style={{ whiteSpace: "pre-wrap" }}
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          textAlign: "center",
                        }}
                      >
                        {/* {context.companyData.company_name.length > 20
                                                ? context.companyData.company_name.match(/.{1,20}/g).join("\n")
                                                : context.companyData.company_name} */}
                        {context.companyData.company_name}
                      </div>
                    ) : (
                      ""
                    )}
                  </Typography>
                  {/* <Typography
                                    color="white"
                                    variant="body2"
                                    alignItems="center"
                                    fontSize="0.75em"
                                >
                                    San Diego Food Bank
                                </Typography> */}
                </div>
              </Box>
            </Box>
            <Divider sx={{ borderColor: "primary.light" }} />
            <Box
              sx={{
                flexGrow: 1,
                px: 2,
                py: 3,
                backgroundColor: "#71502f",
              }}
            >
              <Stack
                component="ul"
                // spacing={0.5}
                sx={{
                  listStyle: "none",
                  p: 0,
                  m: 0,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "0.2em",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      color: "white",
                      padding: " 0.5em",
                      paddingLeft: "1em",
                      borderRadius: 1,
                      marginBottom: "0.5em",
                      backgroundColor: "rgba(255, 247, 237, 0.1)",
                    }}
                  >
                    <Box sx={{ paddingRight: "0.5em" }}>
                      <BarChartOutlinedIcon />
                    </Box>
                    Perform Diagnostic Analysis
                    {/* Data */}
                  </Box>
                  <Box
                    id="diagnostic-diversity"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      color: "white",
                      borderRadius: 1,
                      padding: "0.1em 0.1em 0.1em 2.5em",
                      backgroundColor: "rgba(255, 247, 237, 0.1)",
                      marginBottom: "0.5em",
                      ...(activeSidenavSelection === "diversity" && {
                        backgroundColor: secondaryColor.light,
                        color: "black",
                        transition: "all 0.9s",
                      }),
                      "&:hover": {
                        backgroundColor: secondaryColor.light,
                        color: "black",
                        transition: "all 0.9s",
                      },
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        paddingRight: "0.5em",
                      }}
                    >
                      <Diversity3Icon />
                    </Box>
                    <ButtonBase
                      sx={{
                        fontSize: "0.75rem",
                      }}
                      onClick={() => {
                        setActiveSidenavSelection("diversity");
                        navigate("/foodbank");
                      }}
                    >
                      Inclusive Excellence
                    </ButtonBase>
                  </Box>
                  <Box
                    id="diagnostic-inclusion"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      color: "white",
                      padding: "0.1em 0.1em 0.1em 2.5em",
                      borderRadius: 1,
                      backgroundColor: "rgba(255, 247, 237, 0.1)",
                      ...(activeSidenavSelection ===
                        "portfolio-optimization" && {
                        backgroundColor: secondaryColor.light,
                        color: "black",
                        transition: "all 0.9s",
                      }),
                      "&:hover": {
                        backgroundColor: secondaryColor.light,
                        color: "black",
                        transition: "all 0.9s",
                      },
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        paddingRight: "0.5em",
                      }}
                    >
                      <AllInclusiveIcon />
                    </Box>
                    <ButtonBase
                      style={{
                        fontSize: "0.75rem",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      onClick={() => {
                        setActiveSidenavSelection("portfolio-optimization");
                        navigate("/foodbank/survey");
                      }}
                    >
                      Inclusion
                    </ButtonBase>
                  </Box>
                  <Box
                    id="climate"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      color: "white",
                      borderRadius: 1,
                      padding: " 0.5em",
                      paddingLeft: "1em",
                      backgroundColor: "rgba(255, 247, 237, 0.1)",
                      ...(activeSidenavSelection === "climate" && {
                        backgroundColor: secondaryColor.light,
                        color: "black",
                        transition: "all 0.9s",
                      }),
                      marginTop: "0.5em",
                      // marginBottom: '0.5em',
                      "&:hover": {
                        backgroundColor: secondaryColor.light,
                        color: "black",
                        transition: "all 0.9s",
                      },
                    }}
                  >
                    <ButtonBase
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        borderRadius: 1,
                        fontSize: "1em",
                      }}
                      onClick={() => {
                        setActiveSidenavSelection("climate");
                        navigate("/foodbank/climate");
                      }}
                    >
                      <Box
                        sx={{
                          paddingRight: "0.5em",
                        }}
                      >
                        <CabinIcon />
                      </Box>
                      Campus Climate
                    </ButtonBase>
                  </Box>
                  <Box
                    id="blueprint"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      color: "white",
                      borderRadius: 1,
                      padding: " 0.5em",
                      paddingLeft: "1em",
                      backgroundColor: "rgba(255, 247, 237, 0.1)",
                      ...(activeSidenavSelection === "blueprint" && {
                        backgroundColor: secondaryColor.light,
                        color: "black",
                        transition: "all 0.9s",
                      }),
                      marginTop: "0.5em",
                      marginBottom: "0.5em",
                      "&:hover": {
                        backgroundColor: secondaryColor.light,
                        color: "black",
                        transition: "all 0.9s",
                      },
                    }}
                  >
                    <ButtonBase
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "start",
                        borderRadius: 1,
                        fontSize: "1em",
                        textAlign: "left"
                      }}
                      onClick={() => {
                        setActiveSidenavSelection("blueprint");
                        navigate("/foodbank/blueprint");
                      }}
                    >
                      <Box
                        sx={{
                          paddingRight: "0.5em",
                        }}
                      >
                        <SettingsSuggestOutlinedIcon />
                      </Box>
                      {/* Data-Informed Intervention */}
                      <div
                        style={{
                          justifyContent: "flex-start",
                          alignItems: "start",
                        }}
                      >
                        Design and Implement Data-Informed Initiatives
                      </div>
                    </ButtonBase>
                  </Box>
                  <Box
                    id="success-tracker"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      color: "white",
                      borderRadius: 1,
                      padding: " 0.5em",
                      paddingLeft: "1em",
                      backgroundColor: "rgba(255, 247, 237, 0.1)",
                      ...(activeSidenavSelection === "success-tracker" && {
                        backgroundColor: secondaryColor.light,
                        color: "black",
                        transition: "all 0.9s",
                      }),
                      "&:hover": {
                        backgroundColor: secondaryColor.light,
                        color: "black",
                        transition: "all 0.9s",
                      },
                    }}
                  >
                    <ButtonBase
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        fontSize: "1em",
                      }}
                      onClick={() => {
                        setActiveSidenavSelection("success-tracker");
                        navigate("/foodbank/tracker");
                      }}
                    >
                      <Box sx={{ paddingRight: "0.5em" }}>
                        <AutoGraphOutlinedIcon />
                      </Box>
                      Track & Report on Success
                    </ButtonBase>
                  </Box>
                  <Box
                    id="upload-data"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      borderRadius: 1,
                      marginTop: "0.5em",
                      marginBottom: "0.5em",
                      color: "white",
                      padding: " 0.5em",
                      paddingLeft: "1em",
                      backgroundColor: "rgba(255, 247, 237, 0.1)",
                      ...(activeSidenavSelection === "upload-data" && {
                        backgroundColor: secondaryColor.light,
                        color: "black",
                        transition: "all 0.9s",
                      }),
                      "&:hover": {
                        backgroundColor: secondaryColor.light,
                        color: "black",
                        transition: "all 0.9s",
                      },
                    }}
                  >
                    <ButtonBase
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        fontSize: "1em",
                      }}
                      onClick={() => {
                        setActiveSidenavSelection("upload-data");
                        navigate("/foodbank/upload");
                      }}
                    >
                      <Box
                        sx={{
                          paddingRight: "0.5em",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <UploadFileIcon />
                      </Box>
                      Upload Data
                    </ButtonBase>
                  </Box>
                </Box>
              </Stack>
              <Button
                style={{
                  width: "100%",
                  color: "#FFF7ED",
                  borderColor: "#FFF7ED",
                  bottom: "0px",
                }}
                variant="outlined"
                onClick={() => driverObj.drive()}
              >
                Open Tour
              </Button>
            </Box>
            <Divider sx={{ borderColor: "white" }} />
          </Box>
        )}
        <Outlet />
      </div>
    </React.Fragment>
  );
}
export default withAuthenticator(Sidenav);
