import React from 'react'
import { useState } from 'react';
import { Drawer, FloatButton } from 'antd';
import { Box } from '@mui/material';
import Typography from '../../components/Typography';
import BoxPlot from '../Sections/BoxPlot';
import Button from '@mui/material/Button';
export default function Survey() {
  const [open, setOpen] = useState(false);
  const [showBox, setShowBox] = useState(false);

  const toggleBox = () => {
    setShowBox(!showBox);
    window.open("https://docs.google.com/forms/d/e/1FAIpQLSezPxy6N6y8li6JFxm54zo_2FtnpGTRJZeGSPWKBI2eqH1v5Q/viewform?embedded=true", '_blank');
  };
  return (
    // <div>Calendar</div>
    //     <Drawer
    //   title="Survey"
    //   placement="right"
    //   onClose={() => setOpen(false)}
    //   open={open}
    //   size="large"
    // >
    <div style={{ height: 300, width: '90%' }}>
      <Box sx={{ display: 'flex', justifyContent: 'center', marginLeft: '30%', flexDirection: 'column' , mt:"4em"}}>
        <Box sx={{
          width: '100%',
          height: '1em',
          padding: '1em',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          mt: '2em'
        }}>
          <Typography variant="h4" marked="center" component="h2" sx={{ mb: 4 }}>
            Survey Report on Inclusion
          </Typography>
        </Box>
        <Box sx={{
          width: '100%',
          height: '60vh',
          boxShadow: 'rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px',
          borderRadius: '5px',
          padding: '1em',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          margin: '1em 0em 1em 0em'
        }}>
          <Typography variant="body1">Survey Analysis</Typography>
          <BoxPlot />
        </Box>
        <Box >
          <Button variant="outlined" onClick={toggleBox} >
            {/* {showBox ? 'Hide Survey' : 'Show Survey'} */}
            Open Survey
          </Button>

          {/* {showBox && (
            <Box
              sx={{
                width: '100%',
                height: '80vh',
                boxShadow: 'rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px',
                borderRadius: '5px',
                padding: '1em',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                margin: '1em 0em 5em 0em',
              }}
            >
              <Typography variant="body1">Survey Form</Typography>
              <iframe
                src="https://docs.google.com/forms/d/e/1FAIpQLSezPxy6N6y8li6JFxm54zo_2FtnpGTRJZeGSPWKBI2eqH1v5Q/viewform?embedded=true"
                width="100%"
                height="100%"
                frameBorder="0"
                marginHeight="0"
                marginWidth="0"
              >
                Loading…
              </iframe>
            </Box>
          )} */}
        </Box>
      </Box>
    </div>
  )
}
