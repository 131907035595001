import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Typography from "../components/Typography";

function AboutUs() {
  const backgroundDots = "/productCTAImageDots.png";

  return (
    <Container component="section" sx={{ mt: 10, display: "flex" }} id="about">
      <Grid container>
        <Grid item xs={12} md={8} sx={{ zIndex: 1 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              bgcolor: "warning.main",
              py: 4, // Reduce the vertical padding
              px: 6, // Increase the horizontal padding
              width: "100%", // Increase the width of the container
              margin: "0 auto",
            }}
          >
            <Box sx={{ maxWidth: 600 }}>
              <Typography variant="h2" component="h2" gutterBottom>
                About Us
              </Typography>
              <Box sx={{ mb: 2 }}>
                <Typography variant="body2">
                Brave Spaces resulted from the challenge to design, implement, and track progress on inclusive excellence.
                </Typography>
              </Box>
              <Box sx={{ mb: 2 }}>
                <Typography variant="body2">
                  We understand that a one-size-fits-all approach is at the core
                  of inequity. Hence, we analyze and build with each individual
                  in mind. We believe that each individual brings a unique
                  perspective to and seeks a sense of belonging at work.
                </Typography>
              </Box>
              <Box sx={{ mb: 2 }}>
                <Typography variant="body2">
                  We plan to never let “perfect” be the enemy of “good.” Small
                  steps build self-efficacy, leading to big impacts in the
                  workplace.
                </Typography>
              </Box>
              <Box sx={{ mb: 2 }}>
                <Typography variant="body2">
                We work with you to address inclusive excellence.
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          sx={{ display: { md: "block", xs: "none" }, position: "relative",
          alignItems:"center",
          justifyContent:"center", }}
        >
          <Box
            component="img"
            src="/CEOGangaramSingh.png"
            alt="CEO"
            sx={{
              position: "absolute",
              top: +50,
              left: +50,
              right: +28,
              bottom: 0,
              width: "100%",
              maxWidth: 300,
              backgroundColor: "#FFFFFF",
              objectFit: "contain",
            }}
          />
        </Grid>
      </Grid>
    </Container>
  );
}

export default AboutUs;
