import * as React from 'react';
import { useEffect, useState, useContext } from 'react';
import { DataGrid, GridRowsProp, GridColDef } from '@mui/x-data-grid';
import '../styling/custom-header-cell.css';
import Typography from '../../components/Typography';
import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import axios from "axios";
import FullFeaturedCrudGrid from "./DataGrid"
import { Auth } from "aws-amplify";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import CreateRoundedIcon from '@mui/icons-material/CreateRounded';
import AssignmentIndRoundedIcon from '@mui/icons-material/AssignmentIndRounded';
import EventRepeatRoundedIcon from '@mui/icons-material/EventRepeatRounded';
import EventAvailableRoundedIcon from '@mui/icons-material/EventAvailableRounded';
import DashboardContext from '../../context';

const AdminComponent = () => {
  return (
    <Box sx={{ maxWidth: '60.75em' }}>
      <FullFeaturedCrudGrid />
    </Box>

  );
};

export default function Blueprint() {
  const context = useContext(DashboardContext);

  const [cardData, setCardData] = useState(null);
  const [cardcolor, setCardcolor] = useState();
  const [loading, setLoading] = useState(true);

  // let cardcolor = 'green';


  useEffect(() => {
    const fetchData = () => {
      const url = `https://ej0t9qbga6.execute-api.us-west-1.amazonaws.com/prod/blueprint?company_code=${context.companyData.company_code}`;
      axios.get(url).then((data) => {
        setCardData(data.data);
        console.log(data)
        // switch (data.Items[0].status.S) {
        //   case 'In Progress':
        //     setCardcolor('orange');
        //     break;
        //   case 'Not Started':
        //     setCardcolor('red');
        //     break;
        //   case 'Completed':
        //     setCardcolor('green');
        //     break;
        //   default:
        //     setCardcolor('gray');
        // }
      }).catch((error) => {
        console.error('Error fetching data:', error);
      });
    };

    fetchData();
  }, []);
  //console.log(cardData.Items[0].project_title);
  // const [userGroup, setUserGroup] = useState(null);

  // useEffect(() => {
  //   const fetchUserGroup = async () => {
  //     try {
  //       // Get the authenticated user's information
  //       const userInfo = await Auth.currentUserInfo();

  //       // Get the user's group information from the user attributes
  //       const group = userInfo?.attributes?.["custom:Group_name"];
  //       console.log(userInfo);
  //       setUserGroup(group);
  //     } catch (error) {
  //       console.error("Error fetching user group:", error);
  //     }
  //   };

  //   fetchUserGroup();
  // }, []);

  return (
    <div style={{ height: 300, width: '90%' }}>
      <Box sx={{ display: 'flex', justifyContent: 'center', marginLeft: '25%', mt: "4em" }}>
        <Typography variant="h4" marked="center" component="h2" sx={{ mb: 4 }}>
          Data-Informed Intervention
        </Typography>
      </Box>
      <div style={{ width: '80%', marginLeft: '25%' }}>
        <Typography variant="h6" component="span">
          SMART Actions
        </Typography>
        <div>
          {context.companyData.company_code === "" && <p>Sorry, No smart Actions Found for the company!</p>}
          {context.companyData.company_code !== "bravespaces" && <AdminComponent />}
          {context.companyData.company_code === "bravespaces" && cardData && (
            <div style={{ maxWidth: '100vw', marginTop: 2 }}>
              {cardData.Items.map((item, index) => (
                <Card sx={{ maxWidth: 'auto', color: cardcolor }}>
                  <CardActionArea>
                    <CardContent>
                      <Typography gutterBottom variant="h6" component="div">
                        {/*cardData.Items[0].project_title.S*/}
                        {item.project_title.S}
                      </Typography>
                      <Typography variant="body1" color="text.secondary">
                        {/* {cardData.Items[0].project_description.S} */}
                        {item.project_description.S}
                      </Typography>
                    </CardContent>
                    <Timeline position="alternate">
                      <TimelineItem>
                        <TimelineOppositeContent
                          sx={{ m: 'auto 0' }}
                          align="right"
                          variant="body2"
                          color="text.secondary"
                        >
                          {/* {cardData.Items[0].created_date.S} */}
                          {item.created_date.S}
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                          <TimelineConnector />
                          <TimelineDot>
                            <CreateRoundedIcon />
                          </TimelineDot>
                          <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: '12px', px: 2 }}>
                          <Typography variant="h6" component="span">
                            Date
                          </Typography>
                          <Typography >Process initiated on</Typography>
                        </TimelineContent>
                      </TimelineItem>
                      <TimelineItem>
                        <TimelineOppositeContent
                          sx={{ m: 'auto 0' }}
                          variant="body2"
                          color="text.secondary"
                        >
                          {/* {cardData.Items[0].assigned_to.S} */}
                          {item.assigned_to.S}
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                          <TimelineConnector />
                          <TimelineDot color="primary">
                            <AssignmentIndRoundedIcon />
                          </TimelineDot>
                          <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: '12px', px: 2 }}>
                          <Typography variant="h6" component="span">
                            Manager
                          </Typography>
                          <Typography>Process assigned to</Typography>
                        </TimelineContent>
                      </TimelineItem>
                      <TimelineItem>
                        <TimelineSeparator>
                          <TimelineConnector />
                          <TimelineDot color="primary" variant="outlined">
                            <EventRepeatRoundedIcon />
                          </TimelineDot>
                          <TimelineConnector sx={{ bgcolor: 'secondary.main' }} />
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: '12px', px: 2 }}>
                          <Typography variant="h6" component="span">
                            Status
                          </Typography>
                          <Typography>
                            {/* {cardData.Items[0].status.S} */}
                            {item.status.S}
                          </Typography>
                        </TimelineContent>
                      </TimelineItem>
                      <TimelineItem>
                        <TimelineSeparator>
                          <TimelineConnector sx={{ bgcolor: 'secondary.main' }} />
                          <TimelineDot color="secondary">
                            <EventAvailableRoundedIcon />
                          </TimelineDot>
                          <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: '12px', px: 2 }}>
                          <Typography variant="h6" component="span">
                            Date
                          </Typography>
                          <Typography>Process completed on {item.completion_date.S}
                            {/* {cardData.Items[0].completion_date.S} */}
                          </Typography>
                        </TimelineContent>
                      </TimelineItem>
                    </Timeline>
                    {/* <CardMedia
                    component="img"
                    height="140"
                    src="/diversity_image.jpg" // Replace with the appropriate key from your fetched data
                    alt="diversity image" // Replace with the appropriate key from your fetched data
                  /> */}

                  </CardActionArea>
                </Card>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

