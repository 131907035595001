import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import Container from '@mui/material/Container';
import Typography from '../components/Typography';
import  { useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions} from '@mui/material';


const ImageBackdrop = styled('div')(({ theme }) => ({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  background: '#000',
  opacity: 0.5,
  transition: theme.transitions.create('opacity'),
}));

const ImageIconButton = styled(ButtonBase)(({ theme }) => ({
  position: 'relative',
  display: 'block',
  padding: 0,
  borderRadius: 0,
  height: '60vh',
  [theme.breakpoints.down('md')]: {
    width: '100% !important',
    height: 100,
  },
  '&:hover': {
    zIndex: 1,
  },
  '&:hover .imageBackdrop': {
    opacity: 0.15,
  },
  '&:hover .imageMarked': {
    opacity: 0,
  },
  '&:hover .imageTitle': {
    border: '4px solid currentColor',
  },
  '& .imageTitle': {
    position: 'relative',
    padding: `${theme.spacing(2)} ${theme.spacing(4)} 14px`,
  },
  '& .imageMarked': {
    height: 3,
    width: 18,
    background: theme.palette.common.white,
    position: 'absolute',
    bottom: -2,
    left: 'calc(50% - 9px)',
    transition: theme.transitions.create('opacity'),
  },
}));

const images = [
  {
    url: '/diagnostic.jpg',
    title: 'Perform a Diagnostic Analysis of Inclusive Excellence',
    content: 'We use your HR and Census data to statistically analyze the representation. We collaborate with your HR unit to administer and analyze a 10-item survey of inclusive excellence.',
    width: '30%',
  },
  {
    url: '/Build.jpg',
    title: 'Build a Blueprint to promote Inclusive Excellenc',
    content: 'We work with your HR unit to identify specific initiatives to promote Inclusive Excellence. We help you to build SMART Actions.',
    width: '40%',
  },
  {
    url: '/success.jpg',
    title: 'Track and Report on Inclusive Excellence',
    content:'We use a proprietary web-based application to track and report on Inclusive Excellence.',
    width: '30%',
  },
];

export default function Services() {
  const [openDialogIndex, setOpenDialogIndex] = useState(null);

  const handleOpenDialog = (index) => {
    setOpenDialogIndex(index);
  };

  const handleCloseDialog = () => {
    setOpenDialogIndex(null);
  };
  
  return (
    <Container component="section" sx={{ mt: 8, mb: 4 }} >
      <Typography id="services" variant="h4" marked="center" align="center" component="h2">
        Services We Offer
      </Typography>
      <Box sx={{ mt: 8, display: 'flex', flexWrap: 'wrap' }}>
      {images.map((image, index) => (
        <ImageIconButton
          key={image.title}
          style={{
            width: image.width,
          }}
          onClick={() => handleOpenDialog(index)}
        >
            <Box
              sx={{
                position: 'absolute',
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                backgroundSize: 'cover',
                backgroundPosition: 'center 40%',
                backgroundImage: `url(${image.url})`,
              }}
            />
            <ImageBackdrop className="imageBackdrop" />
            <Box
              sx={{
                position: 'absolute',
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: 'common.white',
                flexDirection: 'column',
              }}
            >
              <Typography
                component="h3"
                variant="button"
                color="inherit"
                className="imageTitle"
                sx={{
                  marginBottom: '1%', 
                }}
              >
                {image.title}
                <div className="imageMarked" />
              </Typography>
              <Typography variant="caption" color="inherit">
                Click to know more
              </Typography>
            </Box>
          </ImageIconButton>
        ))}
        {openDialogIndex !== null && (
          <Dialog open={openDialogIndex !== null} onClose={handleCloseDialog}>
            <DialogTitle>{images[openDialogIndex].title}</DialogTitle>
            <DialogContent dividers>
              <Typography gutterBottom>{images[openDialogIndex].content}</Typography>
            </DialogContent>
            <DialogActions>
              <Button autoFocus onClick={handleCloseDialog} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </Box>
    </Container>
  );
}
