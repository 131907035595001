import * as React from 'react';
import axios from "axios";
import { Box } from '@mui/material';
import Typography from '../../components/Typography';
import Button from '@mui/material/Button';
// const roles = ['Market', 'Finance', 'Development'];


export default function Pipeline() {
    const [censusData, setCensusData] = React.useState([]);
    const [stateCountyMap, setStateCountyMap] = React.useState([]);
    const url = "https://ej0t9qbga6.execute-api.us-west-1.amazonaws.com/prod/censusdata";
    const censusURL = "https://api.census.gov/data/2021/acs/acs1?get=GEO_ID,NAME,B01001_001E,B01001_002E,B01001_026E,B02001_001E,B02001_002E,B02001_003E,B02001_004E,B02001_005E,B02001_006E,B02001_007E,B03003_001E,B03003_003E,B03003_002E,B21001_001E,B21001_002E,B21001_003E,C18108_001E,B18101_010E,B18101_013E,B18101_016E,B18101_029E,B18101_032E,B18101_035E&for=county:*&key=136fc9e65704b381723709dcc56b0dedd1e50793"
    const censusStateCountyMapURL = "https://api.census.gov/data/2021/acs/acs1?get=GEO_ID,NAME&for=county:*&key=136fc9e65704b381723709dcc56b0dedd1e50793"

    const CensusData = (geo_id, name, total_population, sex_male_total, sex_female_total,total_race, race_white, race_black, race_american_indian, race_asian, race_haw_pac, race_others,total_eth, eth_hisp, eth_not_hisp,total_vet, veteran, non_veteran, disability, state, county) => ({ geo_id, name, total_population, sex_male_total, sex_female_total,total_race, race_white, race_black, race_american_indian, race_asian, race_haw_pac, race_others,total_eth, eth_hisp, eth_not_hisp,total_vet, veteran, non_veteran, disability, state, county });
    const StateCountyMap = (geo_id, name, state, county) => ({ geo_id, name, state, county });
    
    const fetchCountyStateMap = () => {
        let stateCountyMapData = [];
        fetch(censusStateCountyMapURL)
            .then((res) => res.json())
            .then((data) => {
                data.map((item, i) => {
                    if (i != 0) stateCountyMapData.push(StateCountyMap(item[0]!=null?item[0]:"0", item[1]!=null?item[1]:"0", item[2]!=null?item[2]:"0", item[3]!=null?item[3]:"0"));
                });
                // console.log(stateCountyMapData);
            });
            setStateCountyMap(stateCountyMapData);
    };

    const fetchInfo = () => {
        let mapData = [];
        fetch(censusURL)
            .then((res) => res.json())
            .then((data) => {
                data.map((item, i) => {
                    if (i != 0) mapData.push(CensusData(
                        item[0]!=null?item[0]:"0", 
                        item[1]!=null?item[1]:"0",
                        item[2]!=null?item[2]:"0",
                        item[3]!=null?((+item[3]/+item[2])*100).toFixed(1):"0", 
                        item[4]!=null?((+item[4]/+item[2])*100).toFixed(1):"0", 
                        item[5]!=null?item[5]:"0", 
                        item[6]!=null?((+item[6]/+item[5])*100).toFixed(1):"0",
                        item[7]!=null?((+item[7]/+item[5])*100).toFixed(1):"0",
                        item[8]!=null?((+item[8]/+item[5])*100).toFixed(1):"0",
                        item[9]!=null?((+item[9]/+item[5])*100).toFixed(1):"0",
                        item[10]!=null?((+item[10]/+item[5])*100).toFixed(1):"0",
                        item[11]!=null?((+item[11]/+item[5])*100).toFixed(1):"0",
                        item[12]!=null?item[12]:"0", 
                        item[13]!=null?((+item[13]/+item[12])*100).toFixed(1):"0",
                        item[14]!=null?((+item[14]/+item[12])*100).toFixed(1):"0",
                        item[15]!=null?item[15]:"0", 
                        item[16]!=null?((+item[16]/+item[15])*100).toFixed(1):"0",
                        item[17]!=null?((+item[17]/+item[15])*100).toFixed(1):"0",
                        item[18]!=null?(((parseInt(item[19])+parseInt(item[20])+parseInt(item[21])+parseInt(item[22])+parseInt(item[23])+parseInt(item[24]))/parseInt(item[18]))*100).toFixed(1):"0",
                        item[19]!=null?item[25]:"0",
                        item[20]!=null?item[26]:"0"));
                });
                // console.log(blueprintData);
            });
            setCensusData(mapData);
    };



    const saveCensusData = async (payload) => {
        const { data } = await axios.post(url, payload, {
            // headers: {
            //     "Access-Control-Allow-Origin": "*",
            //     'x-api-key': 'NUQg1g8vg07dnS30ujuHi2TCGjloWdai7X9tPCy0'
            // }
        }
        )
    };


    React.useEffect(() => {
        fetchInfo(); fetchCountyStateMap();
    }, []);
    return (

        <div style={{ height: 300, width: '90%' }}>
            <Box sx={{ display: 'flex', justifyContent: 'center', marginLeft: '30%', flexDirection: 'column' }}>
                <Typography variant="h4" marked="center" component="h2" sx={{ mb: 4 }}>
                    Census data pipeline
                </Typography>
                <Button onClick={()=>saveCensusData(censusData)}>Save Census Data</Button>
                {/* <Button onClick={()=>fetchCompanyData()}>Save Census Data</Button> */}
            </Box>
        </div>
    )
}
// geo_id
// name
// total_population:B01001_001E
// sex_male_total:B01001_002E
// sex_female_total:B01001_026E
// total_race: B02001_001E
// race_white:B02001_002E
// race_black:B02001_003E
// race_american_indian:B02001_004E
// race_asian:B02001_005E
// race_haw_pac:B02001_006E
// race_others:B02001_007E
// total_eth: B03003_001E
// eth_hisp:B03003_003E
// eth_not_hisp:B03003_002E
// total_vet: B21001_001E 
// veteran:B21001_002E
// non_veteran:B21001_003E
// disability:C18108_001E
//state//county
// Estimate!!Total!!Total civilian noninstitutionalized population = S1810_C01_001E

// Disability breakdown

// B18101_001E	=>Total:	
	
// B18101_010E	=>Total:!!Male:!!18 to 34 years:!!With a disability	
		
// B18101_013E	=>Total:!!Male:!!35 to 64 years:!!With a disability	
		
// B18101_016E	=>Total:!!Male:!!65 to 74 years:!!With a disability		
		
// B18101_029E	=>Total:!!Female:!!18 to 34 years:!!With a disability	
	
// B18101_032E	=>Total:!!Female:!!35 to 64 years:!!With a disability	
		
// B18101_035E	=>Total:!!Female:!!65 to 74 years:!!With a disability	
		
	




// def create_census_data(item_data):
//     print("create census",item_data)
//     response = client.put_item(
//         TableName='census',
//     Item={
//             'geo_id': {
//                 'S': item_data['geo_id']
//             },
//             'name': {
//                 'S': item_data['name']
//             },
//             'total_population': {
//                 'N': item_data['total_population']
//             },
//             'sex_male_total': {
//                 'N': item_data['sex_male_total']
//             },
//             'sex_female_total': {
//                 'N': item_data['sex_female_total']
//             },
//             'race_white': {
//                 'N': item_data['race_white']
//             },
//             'race_black': {
//                 'N': item_data['race_black']
//             },
//             'race_american_indian': {
//                 'N': item_data['race_american_indian']
//             },
//             'race_asian': {
//                 'N': item_data['race_asian']
//             },
//             'race_haw_pac': {
//                 'N': item_data['race_haw_pac']
//             },
//             'race_others': {
//                 'N': item_data['race_others']
//             },
//             'eth_hisp': {
//                 'N': item_data['eth_hisp']
//             },
//             'eth_not_hisp': {
//                 'N': item_data['eth_not_hisp']
//             },
//             'veteran': {
//                 'N': item_data['veteran']
//             },
//             'non_veteran': {
//                 'N': item_data['non_veteran']
//             },
//             'disability': {
//                 'N': item_data['disability']
//             },
//             'state': {
//                 'N': item_data['state']
//             },
//             'county': {
//                 'N': item_data['county']
//             }
            
//         }
//     )

//     response = {
//         'statusCode': 200,
//         'body': 'successfully created item!',
//         'headers': {
//         'Content-Type': 'application/json',
//         'Access-Control-Allow-Origin': '*'
//         },
//     }
      
//     return response
