import React, { useState } from 'react'
import Typography from '../../components/Typography';
import { Box } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import LinearProgress from '@mui/material/LinearProgress';
import FormControl from '@mui/material/FormControl';
import MyResponsiveLine from '../Sections/LineChart';
import MyResponsiveLineUB from '../Sections/LineChart_UniqBelo';
import InputLabel from '@mui/material/InputLabel';
import TracekrVerticalBar from '../Sections/TracekrVerticalBar';
import { data } from "../data/mockdatahorizontalbar";
import { Button } from 'antd';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';


import { useTheme } from '@mui/material/styles';


let data_graph = [
  {
    "id": "Uniqueness",
    "color": "hsl(110, 70%, 50%)",
    "data": [
      {
        "x": "Q 1",
        "y": 0
      },
      {
        "x": "Q 2",
        "y": 4
      },
      {
        "x": "Q 3",
        "y": 3
      },
      {
        "x": "Q 4",
        "y": 4
      },
    ]
  },
];

let data_graph_belongingness = [
  {
    "id": "Belongingness",
    "color": "hsl(110, 70%, 50%)",
    "data": [
      {
        "x": "Q 1",
        "y": 0
      },
      {
        "x": "Q 2",
        "y": 3
      },
      {
        "x": "Q 3",
        "y": 5
      },
      {
        "x": "Q 4",
        "y": 4.4
      },
    ]
  },
];

let data_race_NA = [
  {
    "id": "Company",
    "color": "hsl(78, 70%, 50%)",
    "data": [
      {
        "x": "Jan",
        "y": 1.4
      },
      {
        "x": "Feb",
        "y": 2.4
      },
      {
        "x": "Mar",
        "y": 2.4
      },
      {
        "x": "Apr",
        "y": 2.4
      },
      {
        "x": "May",
        "y": 3
      },
      {
        "x": "Jun",
        "y": 3
      },
      {
        "x": "July",
        "y": 5
      },
      {
        "x": "Aug",
        "y": 5
      },
      {
        "x": "Sep",
        "y": 5
      },
      {
        "x": "Oct",
        "y": 5
      },
      {
        "x": "Nov",
        "y": 5.5
      },
      {
        "x": "Dec",
        "y": 6
      }
    ]
  },
  {
    "id": "County",
    "color": "hsl(292, 70%, 50%)",
    "data": [
      {
        "x": "Jan",
        "y": 35.5
      },
      {
        "x": "Feb",
        "y": 35.5
      },
      {
        "x": "Mar",
        "y": 35.5
      },
      {
        "x": "Apr",
        "y": 40.0
      },
      {
        "x": "May",
        "y": 40.0
      },
      {
        "x": "Jun",
        "y": 41.0
      },
      {
        "x": "July",
        "y": 41.0
      },
      {
        "x": "Aug",
        "y": 41.0
      },
      {
        "x": "Sep",
        "y": 41.0
      },
      {
        "x": "Oct",
        "y": 41.0
      },
      {
        "x": "Nov",
        "y": 41.0
      },
      {
        "x": "Dec",
        "y": 41.0
      }
    ]
  },
];
let data_race_WH = [
  {
    "id": "Company",
    "color": "hsl(78, 70%, 50%)",
    "data": [
      {
        "x": "Jan",
        "y": 1
      },
      {
        "x": "Feb",
        "y": 2
      },
      {
        "x": "Mar",
        "y": 2
      },
      {
        "x": "Apr",
        "y": 2.4
      },
      {
        "x": "May",
        "y": 3
      },
      {
        "x": "Jun",
        "y": 7
      },
      {
        "x": "July",
        "y": 5
      },
      {
        "x": "Aug",
        "y": 5
      },
      {
        "x": "Sep",
        "y": 7
      },
      {
        "x": "Oct",
        "y": 6
      },
      {
        "x": "Nov",
        "y": 5.5
      },
      {
        "x": "Dec",
        "y": 6
      }
    ]
  },
  {
    "id": "County",
    "color": "hsl(292, 70%, 50%)",
    "data": [
      {
        "x": "Jan",
        "y": 35.5
      },
      {
        "x": "Feb",
        "y": 35.5
      },
      {
        "x": "Mar",
        "y": 35.5
      },
      {
        "x": "Apr",
        "y": 40.0
      },
      {
        "x": "May",
        "y": 40.0
      },
      {
        "x": "Jun",
        "y": 41.0
      },
      {
        "x": "July",
        "y": 41.0
      },
      {
        "x": "Aug",
        "y": 41.0
      },
      {
        "x": "Sep",
        "y": 41.0
      },
      {
        "x": "Oct",
        "y": 41.0
      },
      {
        "x": "Nov",
        "y": 41.0
      },
      {
        "x": "Dec",
        "y": 41.0
      }
    ]
  },
];


export default function Tracker() {
  const theme = useTheme();
  const primaryColor = theme.palette.primary;
  const secondaryColor = theme.palette.secondary;
  const [selectedOption, setSelectedOption] = useState(1);

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const dataRace = [];
  let chartData;

  switch (selectedOption) {
    case 1:
      chartData = data_race_NA;
      break;
    case 2:
      chartData = data_race_WH;
      break;
    case 3:
      chartData = data_race_NA;
      break;
    case 4:
      chartData = data_race_WH;
      break;
    case 5:
      chartData = data_race_NA;
      break;
    case 6:
      chartData = data_race_WH;
      break;
    // Add more cases for other options if needed
    default:
      chartData = dataRace;
  }



  const [activeBox, setActiveBox] = useState(1);

  const handleBoxClick = (event) => {
    setActiveBox(event.target.value);
  };

  const bull = (
    <Box
      component="span"
      sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
    >
      •
    </Box>
  );

  function LinearProgressWithLabel(props) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            props.value,
          )}%`}</Typography>
        </Box>
      </Box>
    );
  }


  return (
    <div style={{ height: 300, width: '90%' }}>
      <Box sx={{ display: 'flex', justifyContent: 'center', marginLeft: '25%', mt:"4em" }}>
        <Typography variant="h4" marked="center" component="h2" sx={{ mb: 4 }}>
          Success Tracker & Report
        </Typography>
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'space-between', marginLeft: '25%' }}>
        <Card sx={{ minWidth: 275, background: '#FFF1FC' }}>
          <CardContent>
            <SentimentVeryDissatisfiedIcon />
            <Typography variant="h5" component="div">
              Tasks{bull}Not{bull}Started{bull}
            </Typography>
            <LinearProgressWithLabel value='10' />
            <Typography color='red'>(1/10) tasks not yet started</Typography>
          </CardContent>

        </Card>
        <Card sx={{ minWidth: 275, background: '#F5F5EF' }}>
          <CardContent>
            <SentimentSatisfiedAltIcon />
            <Typography variant="h5" component="div">
              Tasks{bull}In{bull}Progress{bull}
            </Typography>
            <LinearProgressWithLabel value='80' />
            <Typography color='#E4BB10'>(8/10) tasks in progress</Typography>
          </CardContent>
        </Card>
        <Card sx={{ minWidth: 275, background: '#C8E4DB' }}>
          <CardContent>
            <SentimentVerySatisfiedIcon />
            <Typography variant="h5" component="div">
              Tasks{bull}Completed
            </Typography>
            <LinearProgressWithLabel value='10' />
            <Typography color='green'>(1/10) tasks completed</Typography>
          </CardContent>
        </Card>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: '25%', marginTop: '2%' }}>
        <Typography variant="h5" component="div" marginRight="2%">
          Diversity
        </Typography>
        <Select value={activeBox} onChange={handleBoxClick} sx={{backgroundColor:secondaryColor.light}}>
          <MenuItem value={1}>Gender</MenuItem>
          <MenuItem value={2}>Ethinicity</MenuItem>
          <MenuItem value={3}>Race</MenuItem>
          <MenuItem value={4}>Veteran</MenuItem>
          <MenuItem value={5}>Disability</MenuItem>
        </Select>
      </Box>
      <Box display={activeBox === 1 ? 'block' : 'none'}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', marginLeft: '25%', }}>
          <Box sx={{
            width: '160vh',
            height: '70vh',
            boxShadow: 'rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px',
            borderRadius: '5px',
            padding: '1em',

            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            margin: '2em 0em 2em 0em',
          }}>
            <FormControl variant="standard" sx={{ m: 1, minWidth: 120, mt: 5 }}>
              <InputLabel >Gender</InputLabel>
              <Select
                value={selectedOption}
                onChange={handleOptionChange}
                label="Gender"
                sx={{backgroundColor:secondaryColor.light}}
              >
                <MenuItem value={1}>Female</MenuItem>
                <MenuItem value={2}>Male</MenuItem>
              </Select>
            </FormControl>
            <MyResponsiveLine data_graph={chartData}></MyResponsiveLine>
          </Box>
        </Box>
      </Box>
      <Box display={activeBox === 2 ? 'block' : 'none'}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', marginLeft: '25%', }}>
          <Box sx={{
            width: '160vh',
            height: '70vh',
            boxShadow: 'rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px',
            borderRadius: '5px',
            padding: '1em',

            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            margin: '2em 0em 2em 0em',
          }}>
            <FormControl variant="standard" sx={{ m: 1, minWidth: 120, mt: 5 }}>
              <InputLabel >Ethnicity</InputLabel>
              <Select
                value={selectedOption}
                onChange={handleOptionChange}
                label="Ethnicity"
              >
                <MenuItem value={1}>Hispanic</MenuItem>
                <MenuItem value={2}>Non Hispanic</MenuItem>
              </Select>
            </FormControl>
            <MyResponsiveLine data_graph={chartData}></MyResponsiveLine>
          </Box>
        </Box>
      </Box>
      <Box display={activeBox === 3 ? 'block' : 'none'}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', marginLeft: '25%', }}>
          <Box sx={{
            width: '160vh',
            height: '70vh',
            boxShadow: 'rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px',
            borderRadius: '5px',
            padding: '1em',

            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            margin: '2em 0em 2em 0em',
          }}>
            <FormControl variant="standard" sx={{ m: 1, minWidth: 120, mt: 5 }}>
              <InputLabel >Race</InputLabel>
              <Select
                value={selectedOption}
                onChange={handleOptionChange}
                label="Race"
              >
                <MenuItem value={1}>Native American</MenuItem>
                <MenuItem value={2}>White</MenuItem>
                <MenuItem value={3}>Asian</MenuItem>
                <MenuItem value={4}>Black</MenuItem>
                <MenuItem value={5}>Pacific Islander</MenuItem>
                <MenuItem value={6}>Not Disclosed</MenuItem>
              </Select>
            </FormControl>
            <MyResponsiveLine data_graph={chartData}></MyResponsiveLine>
          </Box>
        </Box>
      </Box>
      <Box display={activeBox === 4 ? 'block' : 'none'}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', marginLeft: '25%', }}>
          <Box sx={{
            width: '160vh',
            height: '60vh',
            boxShadow: 'rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px',
            borderRadius: '5px',
            padding: '1em',

            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            margin: '2em 0em 2em 0em',
          }}>
            <FormControl variant="standard" sx={{ m: 1, minWidth: 120, mt: 5 }}>
              <InputLabel >Veteran</InputLabel>
              <Select
                value={selectedOption}
                onChange={handleOptionChange}
                label="Veteran"
              >
                <MenuItem value={1}>Veteran</MenuItem>
                <MenuItem value={2}>Non Veteran</MenuItem>
              </Select>
            </FormControl>
            <MyResponsiveLine data_graph={chartData}></MyResponsiveLine>
          </Box>
        </Box>
      </Box>
      <Box display={activeBox === 5 ? 'block' : 'none'}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', marginLeft: '25%', }}>
          <Box sx={{
            width: '160vh',
            height: '60vh',
            boxShadow: 'rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px',
            borderRadius: '5px',
            padding: '1em',

            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            margin: '2em 0em 2em 0em',
          }}>
            <FormControl variant="standard" sx={{ m: 1, minWidth: 120, mt: 5 }}>
              <InputLabel >Disability</InputLabel>
              <Select
                value={selectedOption}
                onChange={handleOptionChange}
                label="Disability"
              >
                <MenuItem value={1}>Disabled</MenuItem>
                <MenuItem value={2}>Not Disabled</MenuItem>
              </Select>
            </FormControl>
            <MyResponsiveLine data_graph={chartData}></MyResponsiveLine>
          </Box>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', marginLeft: '25%', }}>
        <Box sx={{
          width: '70vh',
          height: '40vh',
          boxShadow: 'rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px',
          borderRadius: '5px',
          padding: '1em',

          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          margin: '2em 0em 2em 0em',
        }}>
          <Typography variant="body1">Uniqueness Index Over Time</Typography>
          <MyResponsiveLineUB data_graph={data_graph}></MyResponsiveLineUB>
        </Box>
        <Box sx={{
          width: '70vh',
          height: '40vh',
          boxShadow: 'rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px',
          borderRadius: '5px',
          padding: '1em',

          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          margin: '2em 0em 2em 0em',
          // marginLeft: '5%',
        }}>
          <Typography variant="body1">Belongingness Index Over Time</Typography>
          <MyResponsiveLineUB data_graph={data_graph_belongingness}></MyResponsiveLineUB>
        </Box>
      </Box>

    </div>
  )
}
