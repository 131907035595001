
import React, { createContext, useContext } from 'react';
const initialState = {
    censusData: [],
    companyData: {},
    genderData: [],
    ethnicityData: [],
    raceData: [],
    disableData: [],
    veteranData: [],
    setCompanyData: () => { },

}


const DashboardContext = React.createContext(initialState);
export default DashboardContext;

