import * as React from "react";
import AppAppBar from "./views/AppAppBar";
import MainBanner from "../src/views/MainBanner";
import withRoot from "./withRoot";
import CompanyValues from "../src/views/CompanyValues";
import Services from "../src/views/Services";
import AboutUs from "../src/views/AboutUs";
import CompanyClients from "../src/views/CompanyClients";
import ProductHowItWorks from "./views/ProductHowItWorks";
import AppFooter from "./views/AppFooter";
import SignIn from "./components/SignIn";
import Landing from "./components/Landing";
import Terms from "./Terms";
import Sidenav from "./foodbank/pages/Sidenav";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Dashboard from "./foodbank/pages/Dashboard";
import Blueprint from "./foodbank/pages/Blueprint";
import Tracker from "./foodbank/pages/Tracker";
import Survey from "./foodbank/pages/Survey";
import CampusClimate from "./foodbank/pages/CampusClimate";
import UploadData from "./foodbank/pages/UploadData";
import FullFeaturedCrudGrid from "./foodbank/pages/DataGrid"
import '@aws-amplify/ui-react/styles.css';
import Pipeline from "./foodbank/CensusPipeline/Pipeline";
import { createContext, useState, useEffect } from "react";
import DashboardContext from './context';
import axios from 'axios';

const gender_url =
  "https://ej0t9qbga6.execute-api.us-west-1.amazonaws.com/prod/upload/gender?company_code=BS01";
const ethnicity_url =
  "https://ej0t9qbga6.execute-api.us-west-1.amazonaws.com/prod/upload/ethnicity?company_code=BS01";
const race_url =
  "https://ej0t9qbga6.execute-api.us-west-1.amazonaws.com/prod/upload/race?company_code=BS01";
const veteran_url =
  "https://ej0t9qbga6.execute-api.us-west-1.amazonaws.com/prod/upload/veteran?company_code=BS01";
const disability_url =
  "https://ej0t9qbga6.execute-api.us-west-1.amazonaws.com/prod/upload/disability?company_code=BS01";
const stats_url =
  "https://ej0t9qbga6.execute-api.us-west-1.amazonaws.com/prod/upload/statistics";

const App = () => {
  // const theme = createTheme();
  const scrollTo = "services!";
  const [censusData, setCensusData] = useState([]);
  const [companyData, setCompanyData] = useState({});
  const [genderData, setGenderData] = useState([]);
  const [ethnicityData, setEthnicityData] = useState([]);
  const [raceData, setRaceData] = useState([]);
  const [disableData, setDisableData] = useState([]);
  const [veteranData, setVeteranData] = useState([]);
  const [statsData, setStatsData] = useState([]);
  const [loading, setLoading] = useState(true);

  const url = "https://ej0t9qbga6.execute-api.us-west-1.amazonaws.com/prod/censusdata";

  useEffect(() => {
    // fetchCountyData();
    // fetchCompanyData();
    // fetchGenderData();
    // fetchEthnicityData();
    // fetchRaceData();
    // fetchVeteranData();
    // fetchDisabilityData();
    // fetchstats();
  }, []);
  // const fetchCountyData = () => {
  //   const county_url = "https://ej0t9qbga6.execute-api.us-west-1.amazonaws.com/prod/censusdata";

  //   axios.get(county_url)
  //     .then((resp) => {
  //       // const items = resp.data.Items || []; // Check if "Items" array exists

  //       let countyData = [];
  //       resp.data.Items.map((item) => {
  //         countyData.push({
  //           race_haw_pac: item.race_haw_pac ? item.race_haw_pac.S : "0",
  //           sex_male_total: item.sex_male_total ? item.sex_male_total.S : "0",
  //           disability: item.disability ? item.disability.S : "0",
  //           company_or_county: item.company_or_county ? item.company_or_county.S : "0",
  //           county: item.county ? item.county.S : "0",
  //           geo_id: item.geo_id ? item.geo_id.S : "0",
  //           race_others: item.race_others ? item.race_others.S : "0",
  //           race_asian: item.race_asian ? item.race_asian.S : "0",
  //           name: item.name ? item.name.S : "0",
  //           race_american_indian: item.race_american_indian ? item.race_american_indian.S : "0",
  //           total_vet: item.total_vet ? item.total_vet.S : "0",
  //           state: item.state ? item.state.S : "0",
  //           veteran: item.veteran ? item.veteran.S : "0",
  //           eth_hisp: item.eth_hisp ? item.eth_hisp.S : "0",
  //           total_population: item.total_population ? item.total_population.S : "0",
  //           total_race: item.total_race ? item.total_race.S : "0",
  //           race_black: item.race_black ? item.race_black.S : "0",
  //           race_white: item.race_white ? item.race_white.S : "0",
  //           total_eth: item.total_eth ? item.total_eth.S : "0",
  //           eth_not_hisp: item.eth_not_hisp ? item.eth_not_hisp.S : "0",
  //           non_veteran: item.non_veteran ? item.non_veteran.S : "0",
  //           sex_female_total: item.sex_female_total ? item.sex_female_total.S : "0",
  //         });
  //       });
  //       console.log(countyData)
  //       setCensusData(countyData);
  //     })
  //     .catch((error) => {
  //       console.log(error)
  //     });
  // }
  // const fetchCompanyData = () => {
  //   const company_url = "https://ej0t9qbga6.execute-api.us-west-1.amazonaws.com/prod/upload/company?company_code=BS01";
  //   axios.get(company_url)
  //     .then((data) => {
  //       console.log(data.data)
  //       let items = {
  //         company_code: data.data.Item.company_code.S,
  //         company_name: data.data.Item.company_name.S,
  //         county: data.data.Item.county.S,
  //         geo_id: data.data.Item.geo_id.S,
  //         state: data.data.Item.state.S
  //       }
  //       setCompanyData(items);
  //     })
  //     .catch((error) => {
  //     });
  // }
  // const fetchGenderData = () => {
  //   return fetch(gender_url, {
  //     // headers: {
  //     //   "x-api-key": "NUQg1g8vg07dnS30ujuHi2TCGjloWdai7X9tPCy0",
  //     // },
  //   })
  //     .then((res) => res.json())
  //     .then((data) => {
  //       const items = data.Items || []; // Check if "Items" array exists
  //       let genderData = [];
  //       items.map((item) => {
  //         genderData.push({
  //           id: item.row_id.N,
  //           row_id: item.row_id.N,
  //           company_code: item.company_code.S,
  //           date: item.date.S,
  //           company_name: item.company_name.S,
  //           count: item.count.N,
  //           county_id: item.county_id.N,
  //           county_name: item.county_name.S,
  //           gender: item.gender.S,
  //           percent: item.percent.S,
  //           geo_id: item.geo_id.S,
  //         });
  //       });
  //       setGenderData(genderData);
  //     });
  // };
  // const fetchEthnicityData = () => {
  //   return fetch(ethnicity_url, {
  //     // headers: {
  //     //   "x-api-key": "NUQg1g8vg07dnS30ujuHi2TCGjloWdai7X9tPCy0",
  //     // },
  //   })
  //     .then((res) => res.json())
  //     .then((data) => {
  //       const items = data.Items || []; // Check if "Items" array exists
  //       let ethnicityData = [];
  //       items.map((item) => {
  //         ethnicityData.push({
  //           id: item.row_id.N,
  //           row_id: item.row_id.N,
  //           company_code: item.company_code.S,
  //           date: item.date.S,
  //           company_name: item.company_name.S,
  //           count: item.count.N,
  //           county_id: item.county_id.N,
  //           county_name: item.county_name.S,
  //           ethnicity: item.ethnicity.S,
  //           percent: item.percent.S,
  //           geo_id: item.geo_id.S,
  //         });
  //       });
  //       setEthnicityData(ethnicityData);
  //     });
  // };
  // const fetchRaceData = () => {
  //   return fetch(race_url, {
  //     // headers: {
  //     //   "x-api-key": "NUQg1g8vg07dnS30ujuHi2TCGjloWdai7X9tPCy0",
  //     // },
  //   })
  //     .then((res) => res.json())
  //     .then((data) => {
  //       const items = data.Items || []; // Check if "Items" array exists
  //       let raceData = [];
  //       items.map((item) => {
  //         raceData.push({
  //           id: item.row_id.N,
  //           row_id: item.row_id.N,
  //           company_code: item.company_code.S,
  //           date: item.date.S,
  //           company_name: item.company_name.S,
  //           count: item.count.N,
  //           county_id: item.county_id.N,
  //           county_name: item.county_name.S,
  //           race: item.race.S,
  //           percent: item.percent.S,
  //           geo_id: item.geo_id.S,
  //         });
  //       });
  //       setRaceData(raceData);
  //     });
  // };
  // const fetchVeteranData = () => {
  //   return fetch(veteran_url, {
  //     // headers: {
  //     //   "x-api-key": "NUQg1g8vg07dnS30ujuHi2TCGjloWdai7X9tPCy0",
  //     // },
  //   })
  //     .then((res) => res.json())
  //     .then((data) => {
  //       const items = data.Items || []; // Check if "Items" array exists
  //       let raceData = [];
  //       items.map((item) => {
  //         raceData.push({
  //           id: item.row_id.N,
  //           row_id: item.row_id.N,
  //           company_code: item.company_code.S,
  //           date: item.date.S,
  //           company_name: item.company_name.S,
  //           count: item.count.N,
  //           county_id: item.county_id.N,
  //           county_name: item.county_name.S,
  //           is_veteran: item.is_veteran.S,
  //           percent: item.percent.S,
  //           geo_id: item.geo_id.S,
  //         });
  //       });
  //       setVeteranData(raceData);
  //     });
  // };
  // const fetchDisabilityData = () => {
  //   return fetch(disability_url, {
  //     // headers: {
  //     //   "x-api-key": "NUQg1g8vg07dnS30ujuHi2TCGjloWdai7X9tPCy0",
  //     // },
  //   })
  //     .then((res) => res.json())
  //     .then((data) => {
  //       const items = data.Items || []; // Check if "Items" array exists
  //       let raceData = [];
  //       items.map((item) => {
  //         raceData.push({
  //           id: item.row_id.N,
  //           row_id: item.row_id.N,
  //           company_code: item.company_code.S,
  //           date: item.date.S,
  //           company_name: item.company_name.S,
  //           count: item.count.N,
  //           county_id: item.county_id.N,
  //           county_name: item.county_name.S,
  //           percent: item.percent.S,
  //           is_disable: item.is_disable.S,
  //           geo_id: item.geo_id.S,
  //         });
  //       });
  //       setDisableData(raceData);
  //     });
  // };

  // const fetchstats = () => {
  //   return fetch(stats_url, {
  //     // Add any headers or options as needed
  //   })
  //     .then((res) => res.json())
  //     .then((data) => {
  //       setStatsData(data);
  //       //return pvaluedata;
  //     });
  // };


  return (
    <React.Fragment>
      <DashboardContext.Provider value={{ statsData, setStatsData, companyData, setCompanyData, censusData, setCensusData, genderData, setGenderData, ethnicityData, setEthnicityData, raceData, setRaceData, disableData, setDisableData, veteranData, setVeteranData }}>
        {/* <ThemeProvider theme={theme}> */}
        {/* <CssBaseline /> */}
        <Routes>
          <Route path="/" element={<Landing />} />
          {/* <Route path="/" element={<FullFeaturedCrudGrid />} /> */}
          <Route path="/login" element={<SignIn />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/censusdata" element={<Pipeline />} />
          <Route path="/foodbank" element={<Sidenav />}>
            <Route index element={<Dashboard />} />
            <Route path="/foodbank/blueprint" element={<Blueprint />} />
            <Route path="/foodbank/tracker" element={<Tracker />} />
            <Route path="/foodbank/survey" element={<Survey />} />
            <Route path="/foodbank/climate" element={<CampusClimate />} />
            <Route path="/foodbank/upload" element={<UploadData />} />
            {/* <Route path="/" element={<Landing />} />
          <Route path="/" element={<Landing />} /> */}
          </Route>
        </Routes>
        {/* </ThemeProvider> */}
      </DashboardContext.Provider>
    </React.Fragment>
  );
};
export default withRoot(App);
